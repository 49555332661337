/**
 * Enum for status based styling.
 */
export enum statusOption {
    NotAvailable = 'not-available',
    NotStarted = 'not-started',
    UnderDiscussion = 'under-discussion',
    Started = 'started',
    InProgress = 'in-progress',
    Complete = 'complete'
}

export type StatusDetails = {
    statusLabel: string;
    statusStyle: string;
}
/**
 * Map of the status numerical value to status label and style.
 */
export const statusTags: Map<number, StatusDetails> = new Map([
    [0, { statusStyle: statusOption.NotAvailable, statusLabel: "N/A" }],
    [1, { statusStyle: statusOption.NotStarted, statusLabel: "Not Started/Not Discussed" }],
    [2, { statusStyle: statusOption.UnderDiscussion, statusLabel: "Under Discussion" }],
    [3, { statusStyle: statusOption.Started, statusLabel: "Started <50%" }],
    [4, { statusStyle: statusOption.InProgress, statusLabel: "In Progress >50%" }],
    [5, { statusStyle: statusOption.Complete, statusLabel: "Complete" }]
])

/**
 * Gets the status label corresponding to the numeric value of status.
 * 
 * @param number numeric value associated with status 
 */
export const getStatusLabel = (index: number): string => {
    const statusDetail = statusTags.get(index);
    if (statusDetail) {
        return statusDetail.statusLabel;
    }
    return '';
}

/**
 * Gets the status style corresponding to the numeric value of status.
 * 
 * @param number numeric value associated with status 
 */
export const getStatusStyle = (index: number): string => {
    const statusDetail = statusTags.get(index);
    if (statusDetail) {
        return statusDetail.statusStyle;
    }
    return '';
}

/**
 * Gets the status label along with  numeric value 
 * corresponding to the numeric value of status.
 * 
 * @param number numeric value associated with status 
 */
export const getStatusSelectOption = (index: number): string => {
    const statusDetail = statusTags.get(index);
    if (statusDetail) {
        return index + " " + statusDetail.statusLabel;
    }
    return '';
}
