import { Grid, Link, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useContext } from 'react';
import { AppWideContext } from "../../AppContext";
import Constants from './Constants';
import './TopBar.scss';

const TopBar: React.FC = () => {
  const context = useContext(AppWideContext)

  const renderLogo = () => {
    return (
      <a className="header-logo-link" href="/">
        <div className="header-logo"></div>
      </a>
    );
  }

  return (
    <div id="topbar">
      <Grid
        gridDefinition={[
          { colspan: { xxs: 1 }, offset: { xxs: 0, xs: 1, s: 1 } },
          { colspan: { xxs: 3, xs: 4, s: 5, l: 7, } },
          { colspan: { xxs: 8, xs: 6, s: 5, l: 3, } }]}
        disableGutters
      >
        {renderLogo()}
        <div>{Constants.CLOUD_MATURITY_ASSESSMENT}</div>
        <SpaceBetween className="header-actions" size="xxl" direction="horizontal">
          <div>{context.appData.loggedInUser}</div>
          <div><Link className="top-bar-link" target="_blank" href="https://tiny.amazon.com/45hlafcg">Submit Issue</Link></div>
          <div><Link className="top-bar-link" target="_blank" href="https://tiny.amazon.com/5nkv0aag">Feature Request</Link></div>
        </SpaceBetween>
      </Grid>
    </div>
  );
}

export default TopBar;