import { AppContext } from "../../../AppContext";
import { Assessment, AssessmentPrompt, AssessmentTemplate } from "@amzn/awscat-aws-assessment-service-typescript-client";

export interface TransformedAssessmentResponse {
    promptIdMap: Map<string, AssessmentPrompt> | null;
    sectionCategoryMap: Map<string, string[]>;
    description?: string | null,
}

/**
 * Gets the assessment template with response.
 * Returns the promptId map and section to category map.
 * 
 * @param context 
 */
export const loadTransformedAssessments = async (context: AppContext, assessmentId: string): Promise<TransformedAssessmentResponse | null> => {
    return loadAssessmentAndTransformedAssessment(context, assessmentId).then(r => {
        return r?.transformed ? r.transformed : null;
    });
}

export const loadAssessmentAndTransformedAssessment = async (context: AppContext, assessmentId: string): Promise<{ assessment: Assessment, transformed: TransformedAssessmentResponse } | null> => {
    let transformedResponse: TransformedAssessmentResponse = {
        promptIdMap: new Map(),
        sectionCategoryMap: new Map()
    }

    const assessmentResult = await loadAssessmentResult(context, assessmentId);
    try {
        if (assessmentResult?.template) {
            transformedResponse = getAssessmentResult(assessmentResult.template);
            transformedResponse.description = assessmentResult.description;
        }
    } catch (error) {
        context.logger.error('Exception while decoding Assessment: ', error);
    }

    return (assessmentResult && transformedResponse) ? { assessment: assessmentResult, transformed: transformedResponse } : null;
}

export const loadAssessmentResult = async (context: AppContext, assessmentId: string): Promise<Assessment | null> => {
    if (context.assessmentClient) {
        const response = await context.assessmentClient.getAssessments({ assessments: [{ id: assessmentId }] }, {
            customerAccount: false,
            permissions: false,
            template: true,
        });

        const assessments = response.data?.getAssessments;
        if (assessments && assessments.items && assessments.items.length === 1 && assessments.items[0]) {
            return assessments.items[0] as Assessment;
        }
    }
    return null;
}

/**
 * Tranform assessment result into 2 maps.
 * One map is of promptId and Pompt.
 * Second map is the category to sections under that category.
 * Returns the promptId map and section to category map.
 * 
 * @param assessmentTemplate template response from backend. 
 */
export const getAssessmentResult = (assessmentTemplate: AssessmentTemplate): TransformedAssessmentResponse => {
    const idPromptMap: Map<string, AssessmentPrompt> = new Map();
    const assessmentPrompts: AssessmentPrompt[] = [];

    let sectionCategoryMap: Map<string, string[]> = new Map();
    //Get all the questions first
    if (assessmentTemplate && assessmentTemplate.sections) {
        for (var section of assessmentTemplate.sections) {
            if (section?.categories) {
                const headers: string[] = [];
                for (var category of section.categories) {
                    if (category?.prompts) {
                        headers.push(category.name);
                        for (var prompt of category.prompts) {
                            if (prompt && prompt.id) {
                                assessmentPrompts.push(prompt);
                                idPromptMap.set(prompt.id, prompt);
                            }
                        }
                    }
                }
                sectionCategoryMap.set(section.id, headers);
            }
        }
    }
    return {
        promptIdMap: idPromptMap,
        sectionCategoryMap: sectionCategoryMap
    };
}
