import { Container, SpaceBetween, Spinner, Toggle } from "@amzn/awsui-components-react";
import { AppWideContext } from '../../../AppContext';
import { useContext, useEffect, useState } from 'react'
import { loadTransformedAssessments, TransformedAssessmentResponse, } from './LoadAssessmentResponse';
import { useHistory } from 'react-router-dom';
import { getHeader, getProgressRows, getSubHeader } from './TableContent';
import './ProgressSummary.scss';
import { getScoreRollups, ScoreRollups } from "../Utils";
import Constants from "../../common/Constants";

interface Props {
}

const ProgressSummary = (props: Props) => {
  const [assessmentResult, setAssessmentResult] = useState<TransformedAssessmentResponse | null>(null);
  const [scoreRollups, setScoreRollups] = useState<ScoreRollups | undefined>(undefined);
  const [rows, setRows] = useState<JSX.Element[] | undefined>(undefined);
  const [showLabels, setShowLabels] = useState(false);
  const context = useContext(AppWideContext)
  const history = useHistory();

  const assessmentId: string = history?.location?.pathname?.split("/assessments/")[1]?.split("/")[0];

  useEffect(() => {
    loadTransformedAssessments(context, assessmentId).then((r) => {
      setAssessmentResult(r);
      setRows(getProgressRows(r, showLabels));
      setScoreRollups(getScoreRollups([r!]));
    });
  }, [context, assessmentId, showLabels]);

  return (
    <Container className="horizontal-sroll">
      {!rows && <Spinner />}
      {rows &&
        <SpaceBetween direction="vertical" size="s">
          <Toggle checked={showLabels} onChange={({ detail }) => setShowLabels(detail.checked)}>{Constants.SHOW_LABELS}</Toggle>
          <table style={{ scrollMarginTop: 'auto' }} className="progressTable">
            <tbody>
              {getHeader(assessmentResult?.sectionCategoryMap, scoreRollups)}
              {getSubHeader(assessmentResult?.sectionCategoryMap, scoreRollups)}
              {rows && rows}
            </tbody>
          </table>
        </SpaceBetween>
      }
    </Container>
  );
}

export default ProgressSummary;