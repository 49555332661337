import { AssessmentPrompt, AssessmentResponseRating, AssessmentSection } from "@amzn/awscat-aws-assessment-service-typescript-client";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { AppWideContext } from "../../../AppContext";
import { getEnablerTitleAndQuestion } from "../Utils";
import { loadAssessmentResult } from "./LoadAssessmentResponse";
import { statusTags } from "./StatusTags";
import "./CsvTable.scss"
import Constants from "../../common/Constants";
import { Button, SpaceBetween, Spinner } from "@amzn/awsui-components-react";

const stageNameToLabelMap = new Map<string, string>([
  ["Project", "I. Project"],
  ["Foundation", "II. Foundation"],
  ["Acceleration & Migration", "III. Acceleration & Migration"],
  ["Optimization & Reinvention", "IV. Optimization & Reinvention"],
  ["Disrupt", "V. Disrupt"],
]);

const stageFromDependencyActivity = (name?: string) => {
  if (!name) {
    return "";
  }
  const regExp = /\(([^)]+)\)/;
  const matches = regExp.exec(name);
  if (matches && matches.length > 0) {
    // in case there are other parens, return the last one found.
    const stage = stageNameToLabelMap.get(matches[matches.length - 1]);
    return stage ? stage : "";
  }
  return "";
}

const renderRow = (row: string[]) => {
  return (<tr>
    <td>
      {row[0]}
    </td>
    <td>
      {row[1]}
    </td>
    <td>
      {row[2]}
    </td>
    <td>
      {row[3]}
    </td>
    <td>
      {row[4]}
    </td>
    <td>
      {row[5]}
    </td>
    <td>
      {row[6]}
    </td>
    <td>
      {row[7]}
    </td>
  </tr>);
}

const renderPromptsToCsv = (sectionLabel: string, categoryLabel: string, prompts: (AssessmentPrompt | null)[]) => {
  return prompts.map(prompt => {
    if (prompt) {
      const enablerTitleAndQuestion = getEnablerTitleAndQuestion(prompt);
      const rating = (prompt.response as AssessmentResponseRating)?.intValue || 0;
      const status = statusTags.get(rating);
      const comments = (prompt.response as AssessmentResponseRating)?.comments || '';
      const row = [
        sectionLabel,
        categoryLabel,
        stageFromDependencyActivity(prompt.dependencyActivity?.name),
        enablerTitleAndQuestion[0],
        prompt.context?.text ? prompt.context.text : "",
        enablerTitleAndQuestion[1],
        status?.statusLabel || "Unknown",
        comments,
      ];
      return row;
    }
    return [];
  });
}

const renderRows = (rows: string[][]) => {
  return rows.map(row => {
    return renderRow(row);
  });
}

const renderSectionsToCsv = (sections: (AssessmentSection | null)[]) => {
  return sections.map(section => {
    if (section && section.categories) {
      return section.categories.map(category => {
        if (category && category.prompts && category.prompts.length > 0) {
          return renderPromptsToCsv(section.label, category.name, category.prompts);
        }
        return [];
      }).flat();
    }
    return [];
  }).flat();
}

const HEADERS = [
  Constants.PERSPECTIVE,
  Constants.THEME,
  Constants.STAGE_OF_ADOPTION,
  Constants.ENABLER_TITLE,
  Constants.ENABLER_DESCRIPTION,
  Constants.QUESTION,
  Constants.STATUS,
  Constants.NOTES,
];

const renderHeaders = () => {
  return (<tr>
    {HEADERS.map(header => {
      return (<th>{header}</th>);
    })}
  </tr>);
}

const renderTableContents = (rows: string[][]) => {
  return (
    <table className="all-data-table">
      {renderHeaders()}
      {renderRows(rows)}
    </table>
  );
}


const CsvTable = () => {
  const [promptRows, setPromptRows] = useState<string[][] | null>(null);
  const context = useContext(AppWideContext);
  const history = useHistory();

  const assessmentId: string = history?.location?.pathname?.split("/assessments/")[1]?.split("/")[0];

  useEffect(() => {
    loadAssessmentResult(context, assessmentId).then((r) => {
      if (r && r.template && r.template.sections) {
        setPromptRows(renderSectionsToCsv(r.template.sections));
      }
    });
  }, [context, assessmentId]);

  return (
    <SpaceBetween size="l" direction="vertical">
      <Button variant="normal" disabled={!promptRows}
        onClick={() => {
          const headersText = HEADERS.join(",") + "\r\n";
          const csvText = headersText + promptRows?.map(row => {
            return row.map(item => {
              return `"${item}"`;
            }).join(",");
          }).join("\r\n");
          const encodedUri = window.encodeURI("data:text/csv;charset=utf-8," + csvText!);
          window.open(encodedUri);
        }}
      >{Constants.DOWNLOAD_AS_CSV}</Button>
      {!promptRows && <Spinner />}
      {promptRows &&
        renderTableContents(promptRows)
      }
    </SpaceBetween>
  );
}

export default CsvTable;