import { AssessmentStatus } from "@amzn/awscat-aws-assessment-service-typescript-client";
import { Alert, Button, Checkbox, Form, FormField, Input, Modal, Select, SelectProps, SpaceBetween } from "@amzn/awsui-components-react";
import * as React from "react";
import { useContext, useState } from 'react';
import { AppContext, AppWideContext } from '../../../AppContext';
import Constants from "../../common/Constants";
import { Action, Kind, loadAssmessmentsAsync } from "../Assessments";
import updateAssessmentInfo, { UpdateInfoProps } from './UpdateAssessmentInfo';


export const STATUS_TO_OPTION_MAP: Map<string, SelectProps.Option> = new Map([
  [AssessmentStatus.IN_PROGRESS, {
    value: "0",
    label: "In Progress"
  }],
  [AssessmentStatus.COMPLETED, {
    value: "1",
    label: "Complete"
  }],
])

export const LABEL_TO_STATUS_MAP: Map<string, AssessmentStatus> = new Map([
  ["In Progress", AssessmentStatus.IN_PROGRESS],
  ["Complete", AssessmentStatus.COMPLETED],
])

interface EditModalProps {
  id: string;
  accountCustomerName: string;
  description: string;
  status: string;
  customerAccountID: string;
  isDemoTest: boolean,
  dispatch: React.Dispatch<Action>;
  context: AppContext;
}

const EditModal = (props: EditModalProps) => {
  const context = useContext(AppWideContext);
  const [isProcessing, setIsProcessing] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isTestInput, setIsTestInput] = useState(props.isDemoTest);
  const [description, setDescription] = useState(props.description);
  const [selectedStatusOption, setSelectedStatusOption] = useState(STATUS_TO_OPTION_MAP.get(props.status));
  const [errorAlertVisible, setErrorAlertVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const resetModal = () => {
    setVisible(false);
    setErrorAlertVisible(false);
    setIsTestInput(props.isDemoTest);
    setDescription(props.description);
    setSelectedStatusOption(STATUS_TO_OPTION_MAP.get(props.status));
  }

  const updateInfo = async () => {
    const updateInfoProps: UpdateInfoProps = {
      assessmentId: props.id,
      description: description,
      status: LABEL_TO_STATUS_MAP.get(selectedStatusOption!.label!)!,
      isDemoTest: isTestInput,
      context: context,
    }

    try {
      setErrorAlertVisible(false);
      await updateAssessmentInfo(updateInfoProps);
      const items = await loadAssmessmentsAsync(props.context);
      props.dispatch({ kind: Kind.LoadItems, payload: items });
      setVisible(false);
    } catch (e) {
      setErrorMessage(`${e}`);
      setErrorAlertVisible(true);
    }
    setIsProcessing(false);
  }

  const renderModal = () => {
    return (
      <Modal
        onDismiss={() => resetModal()}
        visible={visible}
        closeAriaLabel={Constants.CLOSE_MODAL}
        size="medium"
        header={Constants.UPDATE_ASSESSMENT_INFO}
      >
        {renderForm()}
      </Modal>);
  }

  const renderForm = () => {
    return (
      <Form
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => resetModal()}>{Constants.CANCEL}</Button>
            <Button variant="primary" loading={isProcessing} formAction="none" onClick={() => {
              setIsProcessing(true);
              updateInfo();
            }} >{Constants.SUBMIT}</Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          <FormField label={Constants.CUSTOMER_ACCOUNT_NAME}>
            <Input
              value={props.accountCustomerName}
              disabled={true} />
          </FormField>
          <FormField label={Constants.ASSESSMENT_DESCRIPTION}>
            <Input
              value={description}
              onChange={event => setDescription(event.detail.value)}
              placeholder={description || ""}
              disabled={false} />
          </FormField>
          <FormField
            label={Constants.ASSESSMENT_STATUS}
          >
            <Select
              selectedOption={selectedStatusOption!}
              onChange={event => setSelectedStatusOption(event.detail.selectedOption)}
              options={Array.from(STATUS_TO_OPTION_MAP.values())}
            />
          </FormField>
          <Checkbox
            onChange={({ detail }) =>
              setIsTestInput(detail.checked)
            }
            checked={isTestInput}
          >
            {Constants.IS_DEMO_INPUT}
          </Checkbox>
          <Alert
            onDismiss={() => setErrorAlertVisible(false)}
            visible={errorAlertVisible}
            dismissAriaLabel={Constants.CLOSE_ALERT}
            dismissible
            type="error"
            header={Constants.ERROR_UPDATE_ASSESSMENT}
          >
            {errorMessage}
          </Alert>

        </SpaceBetween>
      </Form>)
  }

  return (
    <span>
      <Button iconName="edit" variant="icon" onClick={() => { setVisible(true); setIsProcessing(false); }} />
      {renderModal()}
    </span>
  );
}

export default EditModal;
