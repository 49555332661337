import { Tabs } from '@amzn/awsui-components-react';
import ColorAssessment from "./editAssessment/ColorAssessment";
import ProgressSummary from "./editAssessment/ProgressSummary";
import RadarCharts from "./editAssessment/RadarCharts";
import Resources from "./editAssessment/Resources";
import Constants from "../common/Constants";
import CsvTable from "./editAssessment/CsvTable";

interface Props {
}

const AssessmentDetails = (props: Props) => {
  return (
    <Tabs
      tabs={
        [
          {
            label: Constants.COLOR_ASSESSMENT,
            id: "first",
            content: <ColorAssessment />
          },
          {
            label: Constants.SUMMARY,
            id: "second",
            content: <ProgressSummary />
          },
          {
            label: Constants.ROADMAP_GUIDANCE,
            id: "third",
            content: <Resources />
          },
          {
            label: Constants.CHARTS,
            id: "fourth",
            content: <RadarCharts />
          },
          {
            label: Constants.ALL_DATA,
            id: "fifth",
            content: <CsvTable />
          },
        ]}
    />
  );
}

export default AssessmentDetails;