import { Assessment, AssessmentPrompt, AssessmentResourceAction, AssessmentResponseRating, AssessmentResponseTypes, CreateAssessmentPermissionInput, DeleteAssessmentPermissionInput } from "@amzn/awscat-aws-assessment-service-typescript-client";
import { AppContext } from "../../AppContext";
import { AssessmentViewModel, PermissionViewModel } from "./Assessments";
import { TransformedAssessmentResponse } from "./editAssessment/LoadAssessmentResponse";
import { getStatusLabel, getStatusSelectOption } from "./editAssessment/StatusTags";
import { BUSINESS_UNIT } from '../createAssessment/components/BusinessUnits'
import { mapUserNameToFrontend } from "../../utils/Utils";
import { epochToDate } from "../../utils/timeDate";

export enum Permission {
  View, Update, Full
}
export interface ScoreRollups {
  perspective: {
    labels: string[],
    datasets: {
      label: string,
      data: number[]
      backgroundColor: string,
    }[],
  },
  category: {
    labels: string[],
    datasets: {
      label: string,
      data: number[]
      backgroundColor: string,
    }[],
  },
}

const orderedChartColors = [
  "rgba(256, 0, 0, 0.3)",
  "rgba(11, 10, 208, 0.3)",
  "rgba(45, 253, 20, 0.3)",
  "rgba(202, 13, 234, 0.3)",
  "rgba(69, 15, 125, 0.4)",
  "rgba(233, 191, 96, .4)",
  "rgba(88, 55, 85, 0.4)",
]

const generateBackgroundColor = (colorIndex: number) => {
  if (colorIndex >= orderedChartColors.length) {
    // fall back to random generation if colors exceed the default config
    const a = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    const c = Math.floor(Math.random() * 256);
    return `rgba(${a}, ${b}, ${c}, 0.4)`;
  }

  return orderedChartColors[colorIndex];
}

const truncatedScore = (scoreTotal: number, numScores: number) => {
  const avg = scoreTotal / numScores;
  // truncate to two places past the decimal.
  return avg ? Math.floor(avg * 100) / 100 : avg;
}

export const getScoreRollups = (assessmentResponses: TransformedAssessmentResponse[]): ScoreRollups => {
  const scoreRollups: ScoreRollups = {
    perspective: { labels: [], datasets: [] },
    category: { labels: [], datasets: [] }
  }
  let colorIndex = 0;
  assessmentResponses.forEach((assessment) => {
    let perspectiveData = new Map<string, number>();
    let perspectiveValueCounts = new Map<string, number>();
    let categoryData = new Map<string, number>();
    let categoryValueCounts = new Map<string, number>();
    assessment.promptIdMap?.forEach((val, key) => {
      const keyParts = key.split('-');
      const perspective = keyParts[0];
      const perspectiveTotal = perspectiveData.get(perspective) || 0;
      const newPerspectiveVal = (val?.response as AssessmentResponseRating)?.intValue || 0;
      perspectiveData.set(perspective, perspectiveTotal + newPerspectiveVal);
      const currentPerspectiveCount = perspectiveValueCounts.get(perspective) || 0;
      perspectiveValueCounts.set(perspective, currentPerspectiveCount + 1);

      const category = keyParts[1];
      const categoryTotal = categoryData.get(category) || 0;
      const newCategoryVal = (val?.response as AssessmentResponseRating)?.intValue || 0;
      categoryData.set(category, categoryTotal + newCategoryVal);
      const currentCategoryCount = categoryValueCounts.get(category) || 0;
      categoryValueCounts.set(category, currentCategoryCount + 1);
    });

    if (scoreRollups.perspective.labels.length === 0) {
      scoreRollups.perspective.labels = Array.from(perspectiveData.keys());
      scoreRollups.category.labels = Array.from(categoryData.keys());
    }

    const backgroundColor = generateBackgroundColor(colorIndex);
    colorIndex++;

    const perspectiveArray: number[] = scoreRollups.perspective.labels.map((label) => {
      return truncatedScore(perspectiveData.get(label)!, perspectiveValueCounts.get(label)!)
    });
    scoreRollups.perspective.datasets.push({
      label: assessment.description ? assessment.description : "undefined",
      data: perspectiveArray,
      backgroundColor: backgroundColor,
    });

    const categoryArray: number[] = scoreRollups.category.labels.map((label) => {
      return truncatedScore(categoryData.get(label)!, categoryValueCounts.get(label)!);
    });
    scoreRollups.category.datasets.push({
      label: assessment.description ? assessment.description : "undefined",
      data: categoryArray,
      backgroundColor: backgroundColor,
    });
  });
  return scoreRollups;
}


export const mapPermissionToBackend = (perm: Permission) => {
  switch (perm) {
    case Permission.Full:
      return AssessmentResourceAction.FULL;
    case Permission.Update:
      return AssessmentResourceAction.UPDATE;
    case Permission.View:
      return AssessmentResourceAction.VIEW;
  }
}

const deletePermission = async (assessment: AssessmentViewModel, p: PermissionViewModel, context: AppContext) => {
  const deleteInput: DeleteAssessmentPermissionInput = {
    assessmentId: assessment.id,
    userId: p.userId,
    action: mapPermissionToBackend(p.action)
  };
  const apiResult = await context.assessmentClient?.deleteAssessmentPermission(deleteInput);
  if (apiResult?.errors) {
    throw new Error(apiResult.errors.toString());
  }
}

const createPermission = async (assessment: AssessmentViewModel, p: PermissionViewModel, context: AppContext) => {
  const createInput: CreateAssessmentPermissionInput = {
    assessmentId: assessment.id,
    action: mapPermissionToBackend(p.action),
    // TODO: is this used for MVP?
    assessmentLink: "https://amazon.com",
    // TODO: use the user's real email address.  Not used for MVP.
    inviteeEmail: `${p.userId}@amazon.com`,
    userId: p.userId
  };
  const apiResult = await context.assessmentClient?.createAssessmentPermission(createInput);
  if (apiResult?.errors) {
    throw new Error(apiResult.errors.toString());
  }
}

export const deletePermissions = async (assessment: AssessmentViewModel, deletedPermissions: PermissionViewModel[], context: AppContext) => {
  for (const p of deletedPermissions) {
    await deletePermission(assessment, p, context);
  }
}

export const createPermissions = async (assessment: AssessmentViewModel, createdPermissions: PermissionViewModel[], context: AppContext) => {
  for (const p of createdPermissions) {
    await createPermission(assessment, p, context);
  }
}

export const getEnablerTitleAndQuestion = (prompt: AssessmentPrompt | null): string[] => {
  let displayTexts = ['', ''];
  if (prompt) {
    const texts: string[] | undefined = prompt.label?.text?.split(":")
    if (texts && texts.length === 2) {
      return texts.map(text => {
        return text.trim();
      });
    }
  }
  return displayTexts;
}

export enum PromptResponseProperty {
  Comments,
  StatusValue,
  StatusLabel
}

export const getPromptResponseProperty = (prompt: AssessmentPrompt, cellProperty: PromptResponseProperty): string | number => {

  if (prompt.responseType === AssessmentResponseTypes.RATING) {
    const response = prompt.response as AssessmentResponseRating;
    switch (cellProperty) {
      case PromptResponseProperty.Comments:
        return response?.comments || '';
      case PromptResponseProperty.StatusLabel:
        return response?.intValue ? getStatusSelectOption(response.intValue) : getStatusLabel(0);
      case PromptResponseProperty.StatusValue:
        return response?.intValue || 0;
    }

  }
  return '';
}

export const getBusinessUnit = (metadata: ({
  key: string;
  value: string;
} | null)[] | null) => {
  if (metadata) {
    const results = metadata.filter(i => i?.key === BUSINESS_UNIT);
    if (results && results.length > 0) {
      return results[0]!.value;
    }
  }
  return "";
}

export const mapAction = (perm: string): Permission => {
  if (perm === 'full' || perm === 'view' || perm === 'update') {
    const backendPermissionActionMap = {
      'full': Permission.Full,
      'view': Permission.View,
      'update': Permission.Update
    }
    return backendPermissionActionMap[perm];
  }
  throw new Error('Unsupported Permission Action');
}

export const viewModelFromItem = (item: Assessment, context: AppContext): AssessmentViewModel | null => {
    const viewModel: AssessmentViewModel = {
      id: item?.id,
      accountCustomerName: item.customerAccount?.accountName || '',
      description: item.description || '',
      businessUnitName: getBusinessUnit(item.metadata),
      createdBy: mapUserNameToFrontend(item.createdBy || ''),
      lastUpdated: item.updatedAt ? epochToDate(item.updatedAt) : undefined,
      status: item.status || '',
      customerAccountID: item.organizationID || '',
      isDemoTest: !!item.isDemoTest,
      isDeliveredRemotely: !!item.deliveredRemotely,
      template: item.template || null,
      permissions: item.permissions ? item.permissions.reduce((accumulator, currentValue) => {
        if (currentValue?.userId) {
          accumulator.push({
            userId: currentValue.userId,
            action: mapAction(currentValue.action.toLowerCase()),
            userEmail: currentValue.inviteeEmail
          })
        } else {
          // We will try and cope with this and move on.
          context.logger.error("Malformed Permission received: ", currentValue);
        }
        return accumulator;
      }, new Array<PermissionViewModel>()) : []
    }
    return viewModel;
}