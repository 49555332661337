import { Alert, Badge, Box, Button, ColumnLayout, Container, FormField, Header, Input, Modal, Select, SpaceBetween, Table, TableProps, TextContent } from '@amzn/awsui-components-react';
import React, { useContext, useState } from 'react';
import { AppWideContext } from '../../AppContext';
import { mapUserNameToBackend, mapUserNameToFrontend } from '../../utils/Utils';
import Constants from '../common/Constants';
import { Action, AssessmentViewModel, Kind, PermissionViewModel } from './Assessments';
import { createPermissions, deletePermissions, Permission } from './Utils';


type Conclusion = {
  newPermissions: PermissionViewModel[],
  changedPermissions: PermissionViewModel[],
  deletedPermissions: PermissionViewModel[]
}

interface Props {
  assessment: AssessmentViewModel;
  dispatch?: React.Dispatch<Action>;
}


enum Change {
  Add, Update, Delete, None
}

interface ShareViewModel extends PermissionViewModel {
  change: Change;
}

interface ShareTableProps {
  permissions: ShareViewModel[];
  setPermissions: React.Dispatch<React.SetStateAction<ShareViewModel[]>>;
}

const ShareTable = (props: ShareTableProps) => {
  const mapChange = (change: Change) => {
    switch (change) {
      case Change.Add:
        return <Badge color="blue">Pending Add</Badge>;
      case Change.Update:
        return <Badge color="blue">Pending Update</Badge>;
      case Change.None:
        return <Badge color="green">Saved</Badge>;
    }
    return <p>Unknown</p>;
  }
  const mapPermission = (permission: Permission) => {
    switch (permission) {
      case Permission.Full:
        return <p>Full</p>;
      case Permission.Update:
        return <p>Update</p>;
      case Permission.View:
        return <p>View</p>;
    }
  }

  const columnDefs: TableProps.ColumnDefinition<ShareViewModel>[] =
    [{
      id: "status",
      header: "Status",
      cell: e => mapChange(e.change),
    },
    {
      id: "userId",
      header: "User Name",
      cell: e => mapUserNameToFrontend(e.userId),
    },
    {
      id: "email",
      header: "Email",
      cell: e => e.userEmail,
    },
    {
      id: "permission",
      header: "Permission",
      cell: e => mapPermission(e.action),
    },
    {
      id: "actions",
      header: "Remove",
      cell: e => {
        return <Button iconName="close" variant="icon" onClick={() => {
          // remove this row by looking it up and setting to Delete
          props.setPermissions(props.permissions.map(p => {
            return (p.userId === e.userId) ? { ...p, change: Change.Delete } : p;
          }));
        }
        } />;
      }
    },
    ];



  return (
    <Table<ShareViewModel>
      trackBy="userId"
      columnDefinitions={columnDefs}
      items={props.permissions.filter(p => p.change !== Change.Delete)}
      loadingText={Constants.LOADING_RESOURCES}
      empty={
        < Box textAlign="center" color="inherit" >
          <b>No resources</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit">
            No resources to display.
          </Box>
        </Box >
      }
    />
  );
}

const ShareAssessmentDialog = (props: Props) => {
  const [newUserName, setUserName] = useState("");
  const [newPermission, setNewPermission] = useState(Permission.View);
  const [userNameValidationError, setUserNameValidationError] = useState<string>("");
  const [updateInProgress, setUpdateInProgress] = useState(false);
  const [errorAlertVisible, setErrorAlertVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const context = useContext(AppWideContext);

  // This tracks the contents of the table 
  const [permissions, setPermissions] = useState<ShareViewModel[]>(props.assessment.permissions.map(p => { return { ...p, change: Change.None } }));

  const validUserName = newUserName.length > 0;

  const mapPermissionToLabel = (permission: Permission): string => {
    switch (permission) {
      case Permission.Full:
        return "Full";
      case Permission.Update:
        return "Update";
      case Permission.View:
        return "View";
    }
    return "None";
  }

  const mapPermissionFromLabel = (permission?: string) => {
    switch (permission?.toLowerCase()) {
      case "full":
        return Permission.Full;
      case "update":
        return Permission.Update;
      case "view":
        return Permission.View;
    }
    throw new Error(`Unsupported Permission: ${permission}`);
  }

  const validateUserName = (username: string) => {
    // https://merlin.corp.amazon.com/questions/140486#547752
    const USERNAME_PATTERN = '^[a-z]{0,64}$'; // 0-64 lower case. Treat empty username as valid
    if (username?.includes('@')) {
      return setUserNameValidationError(Constants.WITHOUT_AT_SIGN);
    } else if (!username?.match(USERNAME_PATTERN)) {
      return setUserNameValidationError(Constants.ERROR_INVALID_USERNAME);
    }
    return setUserNameValidationError('');
  }

  /**
   * shareItemAsync() performs a sequence of API calls in order to mutate
   * permissions as required. This is not ideal because if the browser closes
   * during this sequence, the permissions may be incompletely mutated.  The
   * best course of action is to allow permissions to be mutated in a single
   * server round trip with all-or-nothing semantics.
   * 
   * TODO: Enhance Server API as described above.
   */
  const shareItemAsync = async (
    newPermissions: PermissionViewModel[],
    changedPermissions: PermissionViewModel[],
    deletedPermissions: PermissionViewModel[],
  ) => {
    const assessment = props.assessment;

    if (context.logger.level === 'DEBUG' || context.logger.level === 'VERBOSE') {
      context.logger.debug('shareItemAsync()');
      context.logger.debug('newPermissions: ', newPermissions);
      context.logger.debug('changedPermissions: ', changedPermissions);
      context.logger.debug('deletedPermissions: ', deletedPermissions);
    }

    // 1. delete the deletedPermissions
    await deletePermissions(assessment, deletedPermissions, context);
    // 2. delete the changedPermissions
    await deletePermissions(assessment, changedPermissions, context);
    // 3. create the changedPermissions
    await createPermissions(assessment, changedPermissions, context);
    // 4. create the newPermissions
    await createPermissions(assessment, newPermissions, context);
  }

  const onConfirmShare = async (conclusion: Conclusion) => {
    setUpdateInProgress(true);
    shareItemAsync(conclusion.newPermissions, conclusion.changedPermissions, conclusion.deletedPermissions)
      .then(() => {
        // Permission has changed. Reload assessments
        props.dispatch && props.dispatch({ kind: Kind.ReloadItems });
      }).catch((err) => {
        setErrorMessage(Constants.ERROR_UPDATE_PERMISSION + `: ${err}`);
        setErrorAlertVisible(true)
      }).finally(() => {
        setUpdateInProgress(false);
      });
  }

  return (
    <Modal
      visible={true}
      header={`Share: ${props.assessment.description}?`}
      closeAriaLabel="Close dialog"
      onDismiss={() => props.dispatch && props.dispatch({ kind: Kind.CancelAction })}
      footer={
        < Box float="right" >
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => props.dispatch && props.dispatch({ kind: Kind.CancelAction })}>{Constants.CANCEL}</Button>
            <Button
              variant="primary"
              loading={updateInProgress}
              onClick={() => onConfirmShare({
                newPermissions: permissions.filter(p => p.change === Change.Add),
                changedPermissions: permissions.filter(p => p.change === Change.Update),
                deletedPermissions: permissions.filter(p => p.change === Change.Delete),
              })}
              disabled={permissions.filter(p => p.change !== Change.None).length === 0}>
              {Constants.APPLY}
            </Button>
          </SpaceBetween>
        </Box >
      }
    >
      <ColumnLayout borders="horizontal">
        <div>
          <TextContent>
            <h4>Current User Permissions:</h4>
          </TextContent>
          <ShareTable setPermissions={setPermissions} permissions={permissions} />
        </div>
        <Container
          header={
            <Header
              variant="h2"
              description="Container description"
              actions={
                <SpaceBetween
                  direction="horizontal"
                  size="xs">
                  <Button
                    disabled={userNameValidationError.length > 0 || !validUserName}
                    onClick={() => {
                      const newPerms = permissions.slice();
                      newPerms.push({
                        action: newPermission,
                        // TODO: use the user's actual email address
                        userEmail: `${newUserName}@amazon.com`,
                        change: Change.Add,
                        userId: mapUserNameToBackend(newUserName)
                      });
                      setPermissions(newPerms);
                      setUserName("");
                      setNewPermission(Permission.View);
                    }}
                  >Add</Button>
                </SpaceBetween>
              }>
              Share this Assessment
            </Header>
          }>
          <FormField label="User Name" errorText={userNameValidationError}>
            <Input placeholder="username (without @amazon.com)" value={newUserName} onChange={event => {
              validateUserName(event.detail.value);
              setUserName(event.detail.value);
            }} />
          </FormField>
          <FormField label="Permission">
            <Select
              onChange={event => setNewPermission(mapPermissionFromLabel(event.detail.selectedOption.label))}
              selectedOption={{ label: mapPermissionToLabel(newPermission) }}
              options={[
                { label: "Full" },
                { label: "Update" },
                { label: "View" },
              ]} />
          </FormField>
        </Container>

        <Alert
          onDismiss={() => setErrorAlertVisible(false)}
          visible={errorAlertVisible}
          dismissAriaLabel={Constants.CLOSE_ALERT}
          dismissible
          type="error"
          header={Constants.ERROR_UPDATE_PERMISSION}
        >
          {errorMessage}
        </Alert>

      </ColumnLayout>
    </Modal >
  )
}

export default ShareAssessmentDialog
