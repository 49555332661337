import { Alert, Box, Button, Modal, SpaceBetween } from "@amzn/awsui-components-react";
import React, { useContext, useState } from "react";
import { AppWideContext } from "../../AppContext";
import Constants from '../common/Constants';
import { Action, AssessmentViewModel, Kind } from "./Assessments";

export interface Props {
  assessment: AssessmentViewModel;
  dispatch: React.Dispatch<Action>;
}

const SnapshotAssessmentDialog = (props: Props) => {
  interface ComponentState {
    snapshotInProgress: boolean,
    errorAlertVisible: boolean,
    errorMessage: string,
  }

  const context = useContext(AppWideContext);
  const [state, setState] = useState<ComponentState>({
    snapshotInProgress: false,
    errorAlertVisible: false,
    errorMessage: '',
  });


  const snapshotItemAsync = async () => {
    try {
      setState({ ...state, snapshotInProgress: true, errorAlertVisible: false });
      await context.assessmentClient?.createAssessmentSnapshot({ assessmentId: props.assessment.id, description: props.assessment.description });
      setState({ ...state, snapshotInProgress: false });
      props.dispatch({ kind: Kind.CancelAction });
    } catch (e) {
      setState({ ...state, snapshotInProgress: false, errorAlertVisible: true, errorMessage: `${e}` });
    }
  }

  return (<Modal
    visible={true}
    header={Constants.CREATE_SNAPSHOT}
    closeAriaLabel={Constants.CLOSE_DIALOG}
    onDismiss={() => props.dispatch && props.dispatch({ kind: Kind.CancelAction })}
    footer={
      < Box float="right" >
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" onClick={() => props.dispatch && props.dispatch({ kind: Kind.CancelAction })}>{Constants.CANCEL}</Button>
          <Button variant="primary" loading={state.snapshotInProgress} onClick={() => snapshotItemAsync()}>{Constants.CREATE_SNAPSHOT}</Button>
        </SpaceBetween>
      </Box >
    }
  >
    {Constants.CREATE_SNAPSHOT_FOR} '{props.assessment.description}'?

    <Alert
      onDismiss={() => setState({ ...state, errorAlertVisible: false })}
      visible={state.errorAlertVisible}
      dismissAriaLabel={Constants.CLOSE_ALERT}
      dismissible
      type="error"
      header={Constants.ERROR_SNAPSHOT_ASSESSMENT}
    >
      {state.errorMessage}
    </Alert>
  </Modal>);
}

export default SnapshotAssessmentDialog;