import '@amzn/awsui-global-styles/polaris.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter
} from "react-router-dom";
import Authentication from './Authentication';
import { AuthWrapper } from './AuthWrapper';
import './index.css';
import reportWebVitals from './reportWebVitals';

// inject below as a dependency.
const auth = new Authentication();

// Design here is to implement Authentication as a wrapper around the <App/>.
// The <App/> should not be concerned with Auth and assumes we are Authenticated.
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AuthWrapper authentication={auth} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();





