import { A2SClient } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Logger } from 'aws-amplify';
import React from 'react';



export enum Kind {
  Login, Logout
}

// This is a Discriminated Union.  Inspiration: https://dev.to/torbenrahbekkoch/comment/n3oj
export type Action = {
  kind: Kind.Login;
  payload: string;
} | {
  kind: Kind.Logout;
}

export interface AppData {
  loggedInUser: string | null;
}

// Context consists of data and mutators
export interface AppContext {
  appData: AppData;
  dispatch: React.Dispatch<Action>;
  assessmentClient: A2SClient | null;
  logger: Logger;
}

export const initialState: AppData = {
  loggedInUser: null
}


// This is the application-wide context.  You can store all sorts of things in
// it intended for use through out the application.  Beware though, it is a 
// type of global variable.  Self-contained components should not store data in
// this context.
// See: https://reactjs.org/docs/context.html
export const AppWideContext = React.createContext<AppContext>(
  {
    appData: initialState,
    dispatch: (s) => { },
    assessmentClient: null,
    logger: new Logger('DefaultLogger')
  }
);

