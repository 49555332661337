// Must be a singleton due to underlying A2SClient being singletion only.

import { Alert, Spinner, TextContent } from "@amzn/awsui-components-react";
import { Logger } from "aws-amplify";
import React, { useEffect, useReducer } from "react";
import App from "./App";
import { Action, AppData, initialState, Kind } from "./AppContext";
import Authentication from "./Authentication";

// Top-level reducer for the App
const appLevelReducer = (state: AppData, action: Action): AppData => {
  switch (action.kind) {
    case Kind.Login: {
      return { ...state, loggedInUser: action.payload };
    }
    case Kind.Logout: {
      return { ...state, loggedInUser: null };
    }
  }
}

type AuthWrapperProps = {
  authentication: Authentication
}

const logger = new Logger('AuthenticationWrapperLogger');

// We are dealing with sign-in errors out here to simplify the App.  Sign-in 
// may point to a configuration issue (not including getting the password 
// wrong). So if we had a sign-in error, we don't even construct the App.
export const AuthWrapper = (props: AuthWrapperProps) => {

  // Top-level reducer for the app.  useReducer is an alternative to useState
  const [state, dispatch] = useReducer(appLevelReducer, initialState);

  useEffect(() => {
    const updateLoginState = (userName: string | null) => {
      if (userName) {
        dispatch({ kind: Kind.Login, payload: userName });
      } else {
        dispatch({ kind: Kind.Logout });
      }
    }

    const userName = props.authentication.getCurrentAuthenticatedUser();
    if (!userName) {
      updateLoginState(null);
      logger.info('Attempting federated auth...')
      props.authentication.federatedSignIn()
        .then(userName => {
          updateLoginState(userName);
        });
    } else {
      updateLoginState(userName);
    }
  }, [props.authentication]);

  if (props.authentication.getSignInError()) {
    return <div>
      <Alert
        visible={true}
        type="error"
        header="Could not log into service Backend">
        <p>
          Due to an error you were not able to log into the service Backend.
        </p>
        <p>
          The details of the error are included below.
        </p>
      </Alert>
      <TextContent>
        <h3>Error details:</h3>
        <p>{props.authentication.getSignInError()?.message}</p>
        <pre>{props.authentication.getSignInError()?.data.stack}</pre>
      </TextContent>
    </div>
  } else {
    // Injecting this will make it easier to test this function
    if (state.loggedInUser) {
      // Only render the App if we are logged in
      return <App
        appBackendClient={props.authentication.getBackendClient()}
        appWideDispatcher={dispatch}
        appWideState={state} />;
    } else {
      return (
        <Alert
          visible={true}
          header="Logging in..."
        >
          <Spinner size="normal" />
            Accessing Authentication Service...
        </Alert>);
    }
  }
}

