import { Assessment, AssessmentConnection, AssessmentSnapshot, AssessmentSnapshotConnection, AssessmentStatus, AssessmentTemplate, SnapshotsSubQuery } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Box, Button, Header, SpaceBetween, Table, TableProps } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useReducer } from 'react';
import { AppContext, AppWideContext } from '../../AppContext';
import { epochToDate } from '../../utils/timeDate';
import { mapUserNameToFrontend } from '../../utils/Utils';
import Constants from '../common/Constants';
import { AssessmentViewModel } from './Assessments';
import { getAssessmentResult, TransformedAssessmentResponse } from './editAssessment/LoadAssessmentResponse';
import RadarCharts from './editAssessment/RadarCharts';
import { viewModelFromItem } from './Utils';

interface Props {

}

const loadItemsAsync = async (context: AppContext): Promise<AssessmentViewModel[]> => {

  const getAssessmentViewModels = (item: Assessment | null) => {
    const viewModels: AssessmentViewModel[] = [];
    if (item && item.customerAccount && item.customerAccount.accountName &&
      item.description && item.createdBy && item.status && item.status && item.template) {
      if (item.status === AssessmentStatus.COMPLETED) {
        const viewModel = viewModelFromItem(item, context);
        if (viewModel) {
          viewModels.push(viewModel);
          item.snapshotConnection?.snapshots?.forEach((snapshot: AssessmentSnapshot | null) => {
            if (!snapshot || !snapshot.template) {
              return;
            }
            const createTime = snapshot.createdAt ? epochToDate(snapshot.createdAt!).toLocaleString() : "";
            const snapshotViewModel: AssessmentViewModel = {
              id: snapshot.id,
              accountCustomerName: viewModel.accountCustomerName,
              description: `${snapshot.description!} @${createTime}`,
              businessUnitName: viewModel.businessUnitName,
              status: "SNAPSHOT",
              createdBy: mapUserNameToFrontend(snapshot.createdBy!),
              customerAccountID: viewModel.customerAccountID,
              lastUpdated: snapshot.createdAt ? epochToDate(snapshot.createdAt) : epochToDate(0),
              permissions: viewModel.permissions,
              isDemoTest: viewModel.isDemoTest,
              isDeliveredRemotely: viewModel.isDeliveredRemotely,
              template: snapshot.template as AssessmentTemplate,
            }
            viewModels.push(snapshotViewModel);
          });
        }
      }
    }
    return viewModels;
  }

  const getSnapshotConnectionPage = async (assessmentId: string, pageNumber: number) => {
    const defaultSnapshotConnection = { __typename: "AssessmentSnapshotConnection", snapshots: [], nextPage: -1 } as AssessmentSnapshotConnection;
    if (context.assessmentClient) {
      const assessmentResp = await context.assessmentClient.getAssessments(
        { assessments: [{ id: assessmentId }], assessmentType: Constants.CMA_ASSESSMENT_TYPE },
        {
          customerAccount: true,
          permissions: true,
          template: true,
          snapshots: SnapshotsSubQuery.METADATA_AND_CONTENT,
          snapshotsPage: pageNumber,
        }
      );
      const assessment = assessmentResp.data?.getAssessments?.items![0]; // it's just a single assessment
      return assessment?.snapshotConnection ?? defaultSnapshotConnection;
    }
    console.log('Returning default snapshot connection');
    return defaultSnapshotConnection;
  }

  const loadItems = async (assessments: AssessmentConnection) => {
    if (assessments && assessments.items) {
      const mapped: AssessmentViewModel[] = [];
      for (const item of assessments.items) {
        if (!item) { break; }
        // For each assessment, fetch more snapshots if there are more snapshot pages. Then merge with existing snapshots.
        let nextPage = item.snapshotConnection?.nextPage ?? -1;
        while (item.snapshotConnection && nextPage !== -1) {
          const snapshotConnection = await getSnapshotConnectionPage(item.id, nextPage);
          item.snapshotConnection.snapshots = (item.snapshotConnection.snapshots ?? []).concat(snapshotConnection.snapshots);
          nextPage = snapshotConnection.nextPage ?? -1;
        }
        try {
          const assessments = getAssessmentViewModels(item as Assessment);
          mapped.push.apply(mapped, assessments);
        } catch (error) {
          context.logger.error('Exception while decoding Assessment: ', error);
        }
      }
      return mapped;
    }
    return [];
  }

  // TODO: filter and pagination
  if (context.assessmentClient) {
    // Get assessments, each with first page of snapshots
    let response;
    try {
      response = await context.assessmentClient.getAssessments({ assessmentType: Constants.CMA_ASSESSMENT_TYPE }, {
        customerAccount: true,
        permissions: true,
        template: true,
        snapshots: SnapshotsSubQuery.METADATA_AND_CONTENT
      });
    } catch (error) {
      console.log(error);
      const assessments = error.data?.getAssessments;
      return assessments ? await loadItems(assessments) : [];
    }
    const assessments = response.data?.getAssessments;
    // context.logger.level = 'DEBUG';
    context.logger.debug('getAssessments()', response);
    return assessments ? loadItems(assessments) : [];
  }
  return [];
}

export enum Kind {
  SetSelectedItems, LoadItems, GenerateRadarCharts,
}

// This is a Discriminated Union.  Inspiration: https://dev.to/torbenrahbekkoch/comment/n3oj
export type Action = {
  kind: Kind.LoadItems;
  payload: AssessmentViewModel[];
} | {
  kind: Kind.SetSelectedItems;
  payload: AssessmentViewModel[] | null;
} | {
  kind: Kind.GenerateRadarCharts;
  payload: TransformedAssessmentResponse[];
}

interface AssessmentsViewState {
  context: AppContext;
  items: AssessmentViewModel[];
  selectedItems: AssessmentViewModel[] | null;
  actionedItem: AssessmentViewModel | null;
  currentAction: Kind | null;
  loading: boolean;
  radarData: TransformedAssessmentResponse[] | null;
}

const assessmentReducer = (state: AssessmentsViewState, action: Action): AssessmentsViewState => {
  switch (action.kind) {
    case Kind.LoadItems:
      return { ...state, loading: false, items: action.payload, currentAction: action.kind };

    case Kind.SetSelectedItems:
      return { ...state, selectedItems: action.payload, currentAction: action.kind };

    case Kind.GenerateRadarCharts:
      return { ...state, radarData: action.payload, currentAction: action.kind };
  }
}

interface AssessmentTableProps {
  state: AssessmentsViewState;
  dispatch: React.Dispatch<Action>;
  deleteAsync: (assessment: AssessmentViewModel) => void;
}

const AssessmentsTable = (props: AssessmentTableProps) => {
  const columnDefs: TableProps.ColumnDefinition<AssessmentViewModel>[] =
    [{
      id: "description",
      header: "Description",
      cell: e => e.description,
    },
    {
      id: "accountCustomerName",
      header: "Account Customer Name",
      cell: e => e.accountCustomerName,
    },
    {
      id: "businessUnitName",
      header: "Business Unit",
      cell: e => e.businessUnitName,
    },
    {
      id: "status",
      header: "Status",
      cell: e => e.status
    },
    {
      id: "createdBy",
      header: "Created By",
      cell: e => e.createdBy
    },
    {
      id: "lastUpdated",
      header: "Last Updated",
      cell: e => e.lastUpdated?.toDateString() + " " + e.lastUpdated?.toLocaleTimeString()
    },
    ];

  return (
    <Table<AssessmentViewModel>
      trackBy="id"
      columnDefinitions={columnDefs}
      selectedItems={props.state.selectedItems ? props.state.selectedItems : []}
      onSelectionChange={e => props.dispatch({ kind: Kind.SetSelectedItems, payload: e.detail.selectedItems })}
      items={props.state.items}
      loadingText={Constants.LOADING_RESOURCES}
      loading={props.state.loading}
      selectionType="multi"
      empty={
        < Box textAlign="center" color="inherit" >
          <b>No resources</b>
          <Box
            padding={{ bottom: "s" }}
            variant="p"
            color="inherit">
            No resources to display.
          </Box>
        </Box >
      }
    />
  );
}

const generateRadarData = (items: AssessmentViewModel[] | null): TransformedAssessmentResponse[] => {
  if (!items) {
    return [];
  }
  const transformedResponses = items.filter(item => {
    return item && item.template;
  })
    .map(item => {
      const result = getAssessmentResult(item.template!);
      result.description = item.description;
      return result;
    });
  return transformedResponses;
}

const Reports = (props: Props) => {
  const context = useContext(AppWideContext);
  const [state, dispatch] = useReducer(assessmentReducer, {
    context: context,
    items: [],
    selectedItems: null,
    actionedItem: null,
    currentAction: null,
    loading: true,
    radarData: null,
  })

  // Load once on component mount
  // Using async/await ref: https://www.robinwieruch.de/react-hooks-fetch-data 
  useEffect(() => {
    const getItems = async () => {
      const items = await loadItemsAsync(state.context);
      dispatch({ kind: Kind.LoadItems, payload: items });
    }
    getItems();
  }, [state.context]);

  return (
    <div>
      <SpaceBetween size="m">
        <Header variant="h1">
          {Constants.COMPLETED_ASSESSMENTS_AND_SNAPSHOTS}
        </Header>
        <AssessmentsTable state={state} dispatch={dispatch} deleteAsync={() => { }} />
        <Button variant="primary"
          disabled={!state.selectedItems || !state.selectedItems.length}
          onClick={() => {
            dispatch({ kind: Kind.GenerateRadarCharts, payload: generateRadarData(state.selectedItems) })
          }}>{Constants.GENERATE_RADAR_CHART}</Button>
        {state.radarData && state.radarData.length > 0 &&
          <RadarCharts transformedResponses={state.radarData!} />
        }
      </SpaceBetween >
    </div>
  );
}

export default Reports;


