import { Action, ActionName } from "./ColorAssesssmentActions";
import { Assessment, AssessmentPrompt, AssessmentResponseRating } from "@amzn/awscat-aws-assessment-service-typescript-client";
import { capitalizeString } from "../../../utils/Utils";
import denormalisedTable from "./ColorTableConfig";
import { getEnablerTitleAndQuestion, getPromptResponseProperty, PromptResponseProperty, ScoreRollups } from "../Utils";
import { getStatusStyle } from "./StatusTags";
import { ViewState } from "./ColorAssessment";
import { TransformedAssessmentResponse } from "./LoadAssessmentResponse";
import progressTable from "./ProgressTableConfig";

/**
 * Gets the category names and their column span for the table header
 * 
 * @param sectionCategoryMap map of category to each sections in the category 
 * @param scoreRollups scores of all categories and perspectives
 */
export const getHeader = (sectionCategoryMap: Map<string, string[]> | undefined, scoreRollups?: ScoreRollups) => {
  const index = 1
  if (sectionCategoryMap) {
    const headers = Array.from(sectionCategoryMap.keys()).map((key, i) => {

      const length = sectionCategoryMap.get(key)?.length || 1;
      let keyScore: string | null = null;
      if (scoreRollups) {
        const percentScore = scoreRollups?.perspective.datasets[0].data[i];
        keyScore = percentScore ? percentScore.toString().substring(0, 4) : null;
      }
      key = capitalizeString(key);

      return (
        <th
          className="perspective"
          key={index * (i + 1)}
          colSpan={length}
        >
          {key}
          {keyScore && <div>({keyScore})</div>}
        </th>
      )
    })
    if (headers) {
      return <tr key={index}>
        <th key="0"
          className="perspective"
        ></th>
        {headers}
      </tr>;
    }
  }
  return (<tr></tr>);
}

export const HEADER_TO_SCORE_KEY_MAP: Map<string, string> = new Map([
  ["Education", "education"],
  ["Organizational Enablement", "organizationalEnablement"],
  ["Partnering and ProServe", "partneringAndProServe"],
  ["Governance", "governance"],
  ["Migrations/Development", "migrationsDevelopment"],
  ["Commercial", "commercial"],
  ["Strategic Alignment", "strategicAlignment"],
  ["Platform", "platform"],
  ["Security", "security"],
  ["Operations & Support", "operationsAndSupport"],
  ["Cost Optimization", "costOptimization"],
])

/**
 * Gets the section names as the sub level header of the table.
 * 
 * @param sectionCategoryMap map of category to each sections in the category
 * @param scoreRollups scores of all categories and perspectives
 */
export const getSubHeader = (sectionCategoryMap: Map<string, string[]> | undefined, scoreRollups?: ScoreRollups) => {
  const index = 2;
  let dataIndex = 0;
  if (sectionCategoryMap) {
    const headers = Array.from(sectionCategoryMap.values()).map((values) => {
      return (
        values.map((value, i) => {
          value = capitalizeString(value)
          let keyScore: string | null = null;
          if (scoreRollups) {
            const percentScore = scoreRollups?.category.datasets[0].data[dataIndex];
            keyScore = percentScore ? percentScore.toString().substring(0, 4) : null;
            dataIndex++;
          }
          return (
            <th
              className="theme"
              key={i * index}
            >
              {value}
              {keyScore && <div>({keyScore})</div>}
            </th>)
        })
      )
    })
    if (headers) {
      return <tr key={index}>
        <th key="0"
          className="text-light theme"
        ></th>
        {headers}
      </tr>;
    }
  }
  return (<tr></tr>);
}

export const getProgressRows = (assessmentResult: TransformedAssessmentResponse | null, showLabels?: boolean): JSX.Element[] => {
  return progressTable.activities.map((activity, order) => {
    return (
      <tr key={order}>
        <th
          className="progress level"
          key={order}
          rowSpan={1}>
          <h5>
            {levels[order]}
          </h5>
        </th>
        { activity && activity.promptIdGroups && assessmentResult && assessmentResult.promptIdMap &&
          activity.promptIdGroups.map((group, i) => {
            return getProgressCell({ group: group, assessmentResult: assessmentResult, rowKey: i, showLabels: showLabels });
          })
        }</tr>)
  });
};

interface CellProps {
  group: (string | null)[],
  assessmentResult: TransformedAssessmentResponse,
  rowKey: number,
  showLabels?: boolean;
}

const labelClassFromScore = (score: number) => {
  let classAddition = "";
  switch (score) {
    case 1:
      classAddition = "not-started";
      break;
    case 2:
      classAddition = "under-discussion";
      break;
    case 3:
      classAddition = "started";
      break;
    case 4:
      classAddition = "in-progress";
      break;
    case 5:
      classAddition = "complete";
      break;
    default:
      classAddition = "not-available";
  }
  return "promptLabel " + classAddition;
}

const getPromptLabelHtml = (promptLabels: { text: string, score: number }[]) => {
  const labels = promptLabels.map((label) => {
    return (<div className={labelClassFromScore(label.score)}>{label.text}</div>);
  });
  return (<div className="promptLabels" >{labels}</div>);
};

const getProgressCell = (props: CellProps) => {
  const progressAndLabels = getProgressandLabels(props.group, props.assessmentResult, props.rowKey);
  const percentage = progressAndLabels.percentageScore + "%";

  return (<td
    key={props.rowKey}
    className={`table-cell ${props.showLabels ? " alignTop" : ""}`}
  >
    <div className="progressBarOuter">
      <div className="progressBarInner" style={{ width: percentage }}></div>
      <div className="progressPercentage">{percentage}</div>
    </div>
    {props.showLabels && progressAndLabels.promptLabelHtml}
  </td >);
};

export const getActionRows = (assessmentResult: TransformedAssessmentResponse | null, assessment?: Assessment): JSX.Element[] => {
  return progressTable.activities.map((activity, order) => {
    return (
      <tr key={order}>
        <th
          className="progress level"
          key={order}
          rowSpan={1}>
          <h5>
            {levels[order]}
          </h5>
        </th>
        { activity && activity.promptIdGroups && assessmentResult && assessmentResult.promptIdMap &&
          activity.promptIdGroups.map((group, i) => {
            const progressPercentage = getProgressandLabels(group, assessmentResult, i).percentageScore;
            return getPrescribedActions(order, i, assessment ? assessment : null, progressPercentage);
          })
        }</tr>)
  });
};


const progressColors = {
  complete: {
    r: 164,
    g: 230,
    b: 177
  },
  halfway: {
    r: 255,
    g: 235,
    b: 156
  },
  none: {
    r: 165,
    g: 165,
    b: 165
  }
}

const progressRgb = (percentage: number) => {
  // TODO: Once better colors are decided on we can return to this gradient method.
  // let nextColor = progressColors.halfway;
  // let prevColor = progressColors.none;
  // let percentageDiff = percentage / 50;
  // if (percentage >= 50) {
  //   // find the distance between
  //   percentageDiff = (percentage - 50) / 50;
  //   nextColor = progressColors.complete;
  //   prevColor = progressColors.halfway;
  // }

  // const rDiff = (nextColor.r - prevColor.r);
  // const rVal = (rDiff * percentageDiff) + prevColor.r;
  // const gDiff = (nextColor.g - prevColor.g);
  // const gVal = (gDiff * percentageDiff) + prevColor.g;
  // const bDiff = (nextColor.b - prevColor.b);
  // const bVal = (bDiff * percentageDiff) + prevColor.b;

  // return `rgb(${rVal}, ${gVal}, ${bVal})`;

  let colors = progressColors.none;

  if (percentage >= 90) {
    colors = progressColors.complete;
  } else if (percentage >= 21) {
    colors = progressColors.halfway;
  }

  return `rgb(${colors.r}, ${colors.g}, ${colors.b})`;
}

const getPrescribedActions = (row: number, column: number, assessment: Assessment | null, progressPercentage: number) => {
  const emptyActions = <td className={"table-cell action-cell"} style={{ background: progressRgb(progressPercentage) }}><div></div></td>;
  if (!assessment) {
    return emptyActions;
  }
  const workstreams = assessment.template?.workstreams;
  if (!workstreams || !workstreams.length) {
    return emptyActions;
  }
  const activities = workstreams[column]!.activities![row];
  if (!activities || !activities.prescribedActions || !activities.prescribedActions.length) {
    return emptyActions;
  }
  return (<td className={"table-cell action-cell"} style={{ background: progressRgb(progressPercentage) }}>
    {activities!.prescribedActions && activities!.prescribedActions.map(action => {
      return (<div dangerouslySetInnerHTML={{ __html: action!.text }}></div>);
    })}</td>);
}

const getProgressandLabels = (group: (string | null)[], assessmentResult: TransformedAssessmentResponse, rowKey: number) => {
  let total = 0;
  let promptLabels: { text: string, score: number }[] = [];
  group.forEach((promptId) => {
    if (promptId) {
      const prompt = assessmentResult.promptIdMap!.get(promptId);
      const score = (prompt?.response as AssessmentResponseRating)?.intValue || 0;
      promptLabels.push({ text: getEnablerTitleAndQuestion(prompt!)[0], score: score });
      const responseVal = (prompt?.response as AssessmentResponseRating)?.intValue || 0;
      total += responseVal;
    }
  });
  // there are 5 possible answers for each prompt, hence `group.length * 5` to achieve the max possible points per group.
  const perfectScore = (group.length * 5);
  let score = ((total / perfectScore) * 100).toString();
  const promptLabelHtml = getPromptLabelHtml(promptLabels);
  // remove decimals
  return { percentageScore: parseFloat(score.split(".")[0]), promptLabelHtml: promptLabelHtml };
}

const levels = [
  "I. Project",
  "II. Foundation",
  "III. Acceleration & Migration",
  "IV. Optimization & Reinvention",
  "V. Disrupt"
]

/**
 * Gets the prompt's text as the table cell content and make the cell clickable.
 * An empty cell for sections that don't have enough questions for the level.
 * 
 * @param sectionCategoryMap map of category to each sections in the category 
 */
export const getRows = (state: ViewState, dispatch: React.Dispatch<Action>) => {
  // TODO : currently using the HTML table component, need to move this to Polaris table. 
  // When tried with Polaris table, empty cells were not displayed.

  let rows = state.assessmentResult && denormalisedTable.activities.map((activity, order) => {
    let index: number = 2
    let activityLength = denormalisedTable.activities.length;
    const columnNumber = activity.subRows ? activity.subRows[0]?.length : 0;
    let subRows = activity.subRows.map((row, i) => {
      const height = activity.subRows.length
      const rowKey = index++ * (i + 1) + height;
      return (
        <tr key={rowKey}>
          {i === 0 && <th
            className="level"
            key={order}
            rowSpan={height}>
            <h5>
              {levels[order]}
            </h5>

          </th>}
          {
            row.map((promptId, j) => {
              const cellKey = rowKey * (j + 1);
              if (promptId === null) {
                return <td key={cellKey}
                ></td>
              } else {
                const prompt = state.assessmentResult?.promptIdMap?.get(promptId);
                return prompt ?
                  <td
                    className={getTableCellClassName(prompt)}
                    key={(rowKey + 1) * j}
                    onClick={() => {
                      dispatch({
                        actionName: ActionName.SelectQuestion,
                        currentCell: prompt
                      })
                    }}>
                    {getEnablerTitleAndQuestion(prompt)[0]}
                  </td>
                  : <td key={cellKey} style={{ border: '1px solid black' }}></td>
              }
            })
          }</tr>)
    })
    if (order !== activityLength - 1) {
      subRows.push(getEmptyRow(columnNumber));
    }
    return subRows;
  })
  return rows
}

const getEmptyRow = (columns: number) => {
  let cells = [];
  for (var i = 0; i <= columns; i++) {
    cells.push(<td className="level-separator"></td>)
  }
  return (
    <tr className="no-border">
      {cells}
    </tr>
  );
}

const getTableCellClassName = (prompt: AssessmentPrompt) => {
  const tableStyle = "table-cell";
  const statusIntValue = getPromptResponseProperty(prompt, PromptResponseProperty.StatusValue) as number
  const statusStyle = getStatusStyle(statusIntValue);
  return tableStyle + " " + statusStyle
}
