import { Header, SpaceBetween } from "@amzn/awsui-components-react";
import React from "react";
import { StatusDetails, statusTags } from "./StatusTags";
import '../../../styles/colors.scss'

export interface StatusItem {
    value: number;
    description: string;
    className: string;
}

/**
 * The legends and the page data related to the status.
 */
const Legend = () => {
    let index: number = 1;
    const statusItems: StatusItem[] = [];
    statusTags.forEach((value: StatusDetails, key: number) => {
        if (key !== 0) {

            const item: StatusItem =
            {
                value: index++,
                description: value.statusLabel,
                className: value.statusStyle
            }
            statusItems.push(item);
        }
    });

    return (
        <SpaceBetween size="xxxs">
            <Header variant="h2">
                Legend
            </Header>
            <table className="awsui-util-container-header-description">
                <tbody>
                    <tr><th>Color</th><th>Value</th><th>Description</th></tr>
                    {statusItems.map((e, i) => {
                        return (
                            <tr key={i}>
                                <td className={"legend-item table-cell " + e.className} ></td>
                                <td className="legend-item value"><p>{e.value}</p></td>
                                <td><p className="legend-description">{e.description}</p></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </SpaceBetween>
    );
}

export default Legend;