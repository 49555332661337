import { AccountAssessmentInfo } from '../accountTypes/AccountAssessmentInfo';
import { AutosuggestProps } from '@amzn/awsui-components-react/polaris';

interface BusinessUnitsProps {
  assessmentInfo?: Array<AccountAssessmentInfo>;
}

/**
 * Getting all the businessUnits for a customer account from the existing assessments 
 * 
 * @param props the business unit props containing the assessmentInfo. 
 */
export const getBusinessUnitOptions = (props: BusinessUnitsProps): AutosuggestProps.Option[] => {
  const businessUnitsSet = new Set<string>();
  const businessUnitOptions: AutosuggestProps.Option[] = [];
  if (!props.assessmentInfo) {
    return businessUnitOptions;
  }

  for (var assessment of props.assessmentInfo) {
    if (assessment?.metadata) {
      for (var keyValue of assessment.metadata) {
        if (keyValue?.key === BUSINESS_UNIT && keyValue.value && keyValue.value !== ALL_BUSINESS_UNIT) {
          businessUnitsSet.add(keyValue.value);
        }
      }
    }
  }

  // For dispalying this as an AutoSuggest option in AssessmentDetailsEntry page.
  if (businessUnitsSet && businessUnitsSet.size !== 0) {
    for (let businessUnit of Array.from(businessUnitsSet.values())) {
      const option = {
        "value": businessUnit
      }
      businessUnitOptions.push(option);
    }
  }
  return businessUnitOptions;
}

/**
 * This is used for inserting and retrieveing the key value pair 
 * in metadata of assessments for Business Units.
 */
export const BUSINESS_UNIT = "BusinessUnit"
export const ALL_BUSINESS_UNIT = "All Business units"
