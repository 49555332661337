import { CookieConsentManager, LegalFooter } from '@amzn/awscat-cookie-management';
import * as React from 'react';
import CookieManager from '../../cookie-management/CookieManager';
import './Footer.scss';
export class Footer extends React.Component<any, any> {
    private cookieManager: CookieConsentManager;

    constructor(props: any) {
        super(props);
        this.cookieManager = CookieManager.create();
        this.state = {
            buildVersion: '',
        };
    }

    public render() {
        return (
            <LegalFooter
                privacyText='Privacy'
                siteTermText='Site terms'
                cookiePreferenceText='Cookie preferences'
                onCookiePreferenceClick={this.cookieManager.customizeCookies}
                buildVersion={this.state.buildVersion}
                contactEmail='cma-champions@amazon.com'
            />
        );
    }
}
