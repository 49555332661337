import { Assessment, AssessmentStatus, AssessmentTemplate } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { useCollection } from '@amzn/awsui-collection-hooks';
import { Alert, Box, Button, CollectionPreferences, CollectionPreferencesProps, Header, Link, Pagination, SpaceBetween, Table, TableProps, TextFilter } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { AppContext, AppWideContext } from '../../AppContext';
import Constants from '../common/Constants';
import './Assessments.scss';
import DeleteAssessmentDialog from './DeleteAssessmentDialog';
import EditModal from './EditInfo/EditModal';
import ShareAssessmentDialog from './ShareAssessmentDialog';
import SnapshotAssessmentDialog from './SnapshotAssessmentDialog';
import { Permission, viewModelFromItem } from './Utils';
export interface PermissionViewModel {
  userId: string,
  action: Permission,
  userEmail: string | null,
}

export interface AssessmentViewModel {
  id: string;
  accountCustomerName: string;
  description: string;
  businessUnitName: string;
  status: string;
  createdBy: string;
  customerAccountID: string;
  lastUpdated?: Date;
  permissions: Array<PermissionViewModel>,
  isDemoTest: boolean,
  isDeliveredRemotely: boolean,
  template?: AssessmentTemplate | null,
}

interface Props {

}

export const loadAssmessmentsAsync = (context: AppContext): Promise<AssessmentViewModel[]> => {

  const mapAssessmentsToViewModel = (assessments: Assessment[]) => {
    if (assessments) {
      const mapped: AssessmentViewModel[] = [];
      for (const assessment of assessments) {
        try {
          if (assessment && assessment.customerAccount && assessment.customerAccount.accountName &&
            assessment.description && assessment.createdBy && assessment.status) {

            const assessmentViewModel = viewModelFromItem(assessment, context);
            if (assessmentViewModel) {
              mapped.push(assessmentViewModel);
            }
          }
        } catch (error) {
          // simply log error on proceed with best effort
          context.logger.error('Exception while decoding Assessment: ', error);
        }
      }
      return mapped.sort((a, b) => {
        if (!b.lastUpdated) {
          // sort a before null b
          return -1;
        } else if (!a.lastUpdated) {
          // keep same a, b order
          return 0;
        }
        // most recent first
        return b.lastUpdated.getTime() - a.lastUpdated.getTime();
      });
    }
    return [];
  }

  if (context.assessmentClient) {
    const response = context.assessmentClient.getAssessments({ assessmentType: Constants.CMA_ASSESSMENT_TYPE }, {
      customerAccount: true,
      permissions: true,
      template: false,
    });
    return response.then(r => {
      const results = r.data?.getAssessments;
      // context.logger.level = 'DEBUG';
      context.logger.debug('getAssessments()', r);
      return mapAssessmentsToViewModel(results?.items as Assessment[]);
    }).catch(error => {
      console.log(error);
      // Best effort to map unaffected assessments
      return mapAssessmentsToViewModel(error.data?.getAssessments?.items as Assessment[]);
    })

  }
  return new Promise(() => { });
}

export enum Kind {
  LoadItems,
  ReloadItems,
  InitiateDeleteAction,
  DeleteItemAction,
  InitiateShareAction,
  InitiateSnapshotAction,
  CancelAction,
}

// This is a Discriminated Union.  Inspiration: https://dev.to/torbenrahbekkoch/comment/n3oj
export type Action = {
  kind: Kind.LoadItems;
  payload: AssessmentViewModel[];
} | {
  kind: Kind.ReloadItems;
} | {
  kind: Kind.InitiateDeleteAction | Kind.DeleteItemAction | Kind.InitiateShareAction;
  payload: AssessmentViewModel;
} | {
  kind: Kind.CancelAction;
} | {
  kind: Kind.InitiateSnapshotAction;
  payload: AssessmentViewModel;
}

interface AssessmentsViewState {
  context: AppContext;
  items: AssessmentViewModel[];
  selectedItem: AssessmentViewModel | null;
  actionedItem: AssessmentViewModel | null;
  currentAction: Kind | null;
  loading: boolean;
  itemsUpdated: boolean;
}

interface EmptyStateProps {
  title: string;
  subtitle: string;
}

const assessmentReducer = (state: AssessmentsViewState, action: Action): AssessmentsViewState => {
  switch (action.kind) {
    case Kind.LoadItems:
      return { ...state, loading: false, items: action.payload, itemsUpdated: false, currentAction: action.kind };

    case Kind.ReloadItems:
      return { ...state, loading: false, itemsUpdated: true, currentAction: action.kind };

    case Kind.InitiateShareAction:
    case Kind.InitiateDeleteAction:
    case Kind.InitiateSnapshotAction:
      return { ...state, actionedItem: action.payload, currentAction: action.kind };

    case Kind.DeleteItemAction:
      const newItems = state.items.filter(i => i.id !== action.payload.id);
      return { ...state, selectedItem: null, items: newItems, actionedItem: null, currentAction: action.kind };

    case Kind.CancelAction:
      return { ...state, actionedItem: null, currentAction: null };
  }
}

interface AssessmentTableProps {
  state: AssessmentsViewState;
  dispatch: React.Dispatch<Action>;
}

const AssessmentsTable = (props: AssessmentTableProps) => {
  const [preferences, setPreferences] = useState<CollectionPreferencesProps.Preferences>({ pageSize: Constants.TABLE_DEFAULT_PAGE_SIZE });

  const columnDefs: TableProps.ColumnDefinition<AssessmentViewModel>[] =
    [{
      id: "description",
      header: "Description",
      cell: e => (<Link href={"/assessments/" + e.id + "/edit"}>{e.description}</Link >),
    },
    {
      id: "accountCustomerName",
      header: "Account Customer Name",
      cell: e => e.accountCustomerName,
    },
    {
      id: "businessUnitName",
      header: "Business Unit",
      cell: e => e.businessUnitName,
    },
    {
      id: "status",
      header: "Status",
      cell: e => e.status
    },
    {
      id: "createdBy",
      header: "Created By",
      cell: e => e.createdBy
    },
    {
      id: "lastUpdated",
      header: "Last Updated",
      cell: e => e.lastUpdated?.toDateString() || ''
    },
    {
      id: "actions",
      header: "Actions",
      cell: e => {
        return <div>
          <Button iconName="share" variant="icon" onClick={() => props.dispatch({ kind: Kind.InitiateShareAction, payload: e })} />
          <EditModal id={e.id} accountCustomerName={e.accountCustomerName} description={e.description} status={e.status} customerAccountID={e.customerAccountID} isDemoTest={e.isDemoTest} dispatch={props.dispatch} context={props.state.context} />
          <Button iconName="close" variant="icon" onClick={() => props.dispatch({ kind: Kind.InitiateDeleteAction, payload: e })} />
          <Button variant="normal" onClick={() => props.dispatch({ kind: Kind.InitiateSnapshotAction, payload: e })} disabled={e.status !== AssessmentStatus.COMPLETED} >Snapshot</Button>
        </div>;
      }
    },
    ];

  const EmptyState = ({ title, subtitle }: EmptyStateProps) => (
    <Box textAlign='center' color='inherit'>
      <b>{title}</b>
      <Box padding={{ bottom: 's' }} variant='p' color='inherit'>
        {subtitle}
      </Box>
    </Box>
  );

  const filterItems = (item: AssessmentViewModel, filteringText: string): boolean => {
    const rowItems = [
      item.accountCustomerName,
      item.description,
      item.createdBy,
      item.id,
      item.businessUnitName,
      item.status,
      item.customerAccountID,
    ];
    if (item.lastUpdated) {
      rowItems.push(item.lastUpdated.toString());
    }
    const filteringTextLowerCase = filteringText.toLocaleLowerCase().trim();
    return rowItems.some(rowItem => (
      rowItem?.toLocaleLowerCase().trim().includes(filteringTextLowerCase)
    ));
  };

  const { items, collectionProps, filterProps, paginationProps } = useCollection(
    props.state.items,
    {
      filtering: {
        empty: (
          <EmptyState
            title={Constants.ASSESSMENTS_TABLE_NO_ASSESSEMNTS}
            subtitle={Constants.ASSESSMENTS_TABLE_NO_ASSESSEMNTS_TO_DISPLAY}
          />
        ),
        noMatch: (
          <EmptyState
            title={Constants.ASSESSMENTS_TABLE_NO_ASSESSEMNTS}
            subtitle={Constants.ASSESSMENTS_TABLE_NO_ASSESSEMNTS_TO_DISPLAY}
          />
        ),
        filteringFunction: filterItems,
      },
      pagination: { pageSize: preferences.pageSize },
    },
  );

  const tableOptionsSetting = () => [
    {
      label: '10',
      value: 10,
    },
    {
      label: '25',
      value: 25,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
    {
      label: '200',
      value: 200,
    },
    {
      label: '500',
      value: 500,
    },
  ];

  const tablePreferences = (
    <CollectionPreferences
      onConfirm={({ detail }) => setPreferences(detail)}
      preferences={preferences}
      pageSizePreference={{ title: Constants.TABLE_PAGE_SELECTOR_MESSAGE, options: tableOptionsSetting() }}
      wrapLinesPreference={{ label: Constants.TABLE_WRAPLINES_LABEL, description: Constants.TABLE_WRAPLINES_DESCRIPTION }}
      cancelLabel={Constants.CANCEL}
      confirmLabel={Constants.CONFIRM}
      title={Constants.TABLE_PREFERENCES_TITLE}
    />
  );

  return (
    <Table<AssessmentViewModel>
      {...collectionProps}
      trackBy="id"
      columnDefinitions={columnDefs}
      selectedItems={props.state.selectedItem ? [props.state.selectedItem] : []}
      items={items}
      loadingText="Loading assessments"
      loading={props.state.loading}
      filter={
        <TextFilter {...filterProps} countText={''} filteringPlaceholder={Constants.ASSESSMENTS_TABLE_FILTER_SPACE_HOLDER} />
      }
      pagination={<Pagination {...paginationProps} />}
      preferences={tablePreferences}
    />
  );
}

const Assessments = (props: Props) => {
  const context = useContext(AppWideContext);
  const [state, dispatch] = useReducer(assessmentReducer, {
    context: context,
    items: [],
    selectedItem: null,
    actionedItem: null,
    currentAction: null,
    loading: true,
    itemsUpdated: false,
  })

  // Load once on component mount
  useEffect(() => {
    loadAssmessmentsAsync(state.context)
      .then((items) => dispatch({ kind: Kind.LoadItems, payload: items }));
  }, [state.context, state.itemsUpdated]);


  const getActionDialog = () => {
    if (state.actionedItem) {
      switch (state.currentAction) {

        case Kind.InitiateDeleteAction: {
          return <DeleteAssessmentDialog assessment={state.actionedItem} dispatch={dispatch} />;
        }
        case Kind.InitiateShareAction: {
          return <ShareAssessmentDialog assessment={state.actionedItem} dispatch={dispatch} />;
        }
        case Kind.InitiateSnapshotAction: {
          return <SnapshotAssessmentDialog assessment={state.actionedItem} dispatch={dispatch} />;
        }
      }
      return <div />
    }
  }

  return (
    <div>
      {getActionDialog()}
      <SpaceBetween size="m">
        <Header variant="h1">
          Current Assessments
        </Header>
        <AssessmentsTable state={state} dispatch={dispatch} />
        <Alert>
          <SpaceBetween size="xxxs" direction="vertical">
            <span>{Constants.SNAPSHOT_INFO_1}</span>
            <span>{Constants.SNAPSHOT_INFO_2}</span>
            <span>{Constants.SNAPSHOT_INFO_3}</span>
            <SpaceBetween direction="horizontal" size="s">
              <Button iconName="share" variant="icon" disabled={true}></Button>
              <div className="icon-key-text">{Constants.SHARE_ASSESSMENT_DETAILS}</div>
            </SpaceBetween>
            <SpaceBetween direction="horizontal" size="s">
              <Button iconName="edit" variant="icon" disabled={true}></Button>
              <div className="icon-key-text">{Constants.EDIT_ASSESSMENT_DETAILS}</div>
            </SpaceBetween>
          </SpaceBetween>
        </Alert>
      </SpaceBetween >
    </div>
  );
}

export default Assessments
