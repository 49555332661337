import { A2SClient } from '@amzn/awscat-aws-assessment-service-typescript-client';
import Amplify, { Auth } from 'aws-amplify';
import { determineEnvironment, FrontendEnvironment, FrontendEnvironmentName } from './Environments'
import { Hub, Logger } from 'aws-amplify';
import { mapUserNameToFrontend } from './utils/Utils';


interface AmplifyEvent {
  data: {
    message: string,
    stack: string
  };
  event: string;
  message: string;
}


export default class Authentication {
  private env: {
    frontendEnvName: FrontendEnvironmentName,
    frontendEnv: FrontendEnvironment
  };
  private client: A2SClient;
  private currentAuthenticatedUser: string | null = null;
  private logger = new Logger('AuthenticationLogger');
  private signInError: AmplifyEvent | null = null;

  constructor() {
    const log = this.logger;
    this.env = determineEnvironment();

    const listener = (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          this.logger.debug('user signed in');
          break;
        case 'signOut':
          this.logger.debug('user signed out');
          break;
        case 'signIn_failure':
          this.logger.debug('user sign in failed');
          this.signInError = data.payload as AmplifyEvent;
          break;
        case 'tokenRefresh':
          this.logger.debug('token refresh succeeded');
          break;
        case 'tokenRefresh_failure':
          this.logger.debug('token refresh failed');
          break;
        case 'configured':
          this.logger.debug('the Auth module is configured');
          break;
      }
    }

    Hub.listen('auth', listener);

    Amplify.configure({
      aws_project_region: this.env.frontendEnv.region,
      aws_user_pools_id: this.env.frontendEnv.backendEnvironment.userPoolId,
      aws_user_pools_web_client_id: this.env.frontendEnv.webClientId,
      API: {
        graphql_endpoint: this.env.frontendEnv.backendEnvironment.endpoint,
        graphql_region: this.env.frontendEnv.region,
        graphql_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
        graphql_headers: async function (): Promise<{ Authorization: string }> {
          try {
            // Pass the identity token in the Authorization header instead of the access token
            //
            // By default amplify will pass the access token in the Authorization header, which doesn't contain custom claims
            // (e.g., custom:organizationId would not be present without this)
            const token = (await Auth.currentSession()).getIdToken().getJwtToken();
            return { Authorization: token };
          }
          catch (e) {
            log.error(e);
            return { Authorization: "" };
          }
        }
      }, oauth: {
        domain: this.env.frontendEnv.cognitoDomain,
        scope: ['openid'],
        redirectSignIn: `${window.location.origin}/`,
        redirectSignOut: `${window.location.origin}/`,
        responseType: 'code',
      }
    });

    this.client = new A2SClient({
      domain: this.env.frontendEnv.backendDomain,
      region: this.env.frontendEnv.region,
      webClientId: this.env.frontendEnv.webClientId
    });
  }

  public getBackendClient(): A2SClient {
    return this.client;
  }

  public getCurrentAuthenticatedUser(): string | null {
    return this.currentAuthenticatedUser;
  }

  public getSignInError(): AmplifyEvent | null {
    return this.signInError;
  }

  /**
   * Sign-in. Possibly cause a redirection to Auth service if required.
   * 
   * After successful, when this is executed again on redirect back, the user
   * name will be set non-null.
   */
  public async federatedSignIn(): Promise<string | null> {
    try {
      const response = await Auth.currentAuthenticatedUser();
      if (response) {
        // AmazonCorporate_username => username
        this.currentAuthenticatedUser = mapUserNameToFrontend(response.username);
        return this.currentAuthenticatedUser;
      }
    } catch (error) {
      this.logger.error(error);
      this.currentAuthenticatedUser = null;
      // According to the Amplify Auth.currentAuthenticatedUser(), an exception
      // will be thrown (and caught here), if a user is not logged in.  In that
      // case we catch it here then continue on to Auth.federatedSignIn().
      //
      // If there is a backend Cognito configuration issue, then we should catch
      // that error when Cognito redirects back to us.  See: this.signInError.
      // I.e. we should not get this far in that case, but the the guard below
      // is here for defensive reasons to ensure we don't cause a redirect loop.
      if (this.signInError) {
        return null;
      }
    }
    // Otherwise re-direct
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      // No need for further action.  The state is this object reflects that
      // no user is logged in 
      this.logger.error(error);
    }
    // normally we would not reach this point as the browser would redirect.  
    // If Auth.federatedSignIn() throws and we catch it then we return null
    // to remain "logged-out".
    return null;
  }
}