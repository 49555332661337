import { A2SClient } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { AppLayout, SideNavigation, SideNavigationProps } from '@amzn/awsui-components-react';
import { Logger } from 'aws-amplify';
import React, { useState } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import { Action, AppData, AppWideContext } from './AppContext';
import AssessmentDetails from './views/assessments/AssessmentDetails';
import Assessments from './views/assessments/Assessments';
import Reports from './views/assessments/Reports';
import { Footer } from './views/common/Footer';
import TopBar from './views/common/TopBar';
import Welcome from './views/welcome/Welcome';


const navHeader = { text: 'Cloud Maturity Assessment', href: '/' };
const navItems: SideNavigationProps.Item[] = [
  { type: 'link', text: 'Current Assessments', href: '/assessments' },
  { type: 'link', text: 'Reports', href: '/reports' },
];

interface Props {
  appWideState: AppData,
  appBackendClient: A2SClient,
  appWideDispatcher: React.Dispatch<Action>
}

function App(props: Props) {
  const [navigationOpen, setNavigationOpen] = useState(true);

  // This is just for toggling the content padding below.
  const location = useLocation();

  return (
    <AppWideContext.Provider value={{
      appData: props.appWideState,
      dispatch: props.appWideDispatcher,
      assessmentClient: props.appBackendClient,
      logger: new Logger('AppLogger')
    }}>
      <TopBar />
      < AppLayout
        footerSelector="footer"
        headerSelector="#topbar"
        disableContentPaddings={location.pathname === "/"}
        content={
          < Switch >
            <Route path="/assessments/:assessmentid/edit">
              <AssessmentDetails />
            </Route>
            <Route exact path="/assessments">
              <Assessments />
            </Route>
            <Route exact path="/reports">
              <Reports />
            </Route>
            <Route exact path="/">
              <Welcome />
            </Route>
          </Switch >
        }
        navigation={< SideNavigation items={navItems} header={navHeader} />}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
        toolsHide={true}
      />
      <Footer />
    </AppWideContext.Provider >
  );
}

export default App;
