/* eslint-disable */
export default {
  ALL_DATA: "All Data",
  AMAZON_CORPORATE_USER_PREFIX: "AmazonCorporate_",
  APPLY: "Apply",
  ASSESSMENT_DESCRIPTION: "Assessment Description",
  ASSESSMENT_DETAILS_AND_INSTRUCTIONS: "Assessment details and instructions are ",
  ASSESSMENT_STATUS: "Assessment Status",
  ASSESSMENTS_TABLE_FILTER_SPACE_HOLDER: "Find assessments",
  ASSESSMENTS_TABLE_NO_ASSESSEMNTS: "No assessments",
  ASSESSMENTS_TABLE_NO_ASSESSEMNTS_TO_DISPLAY: "No assessments to display.",
  CANCEL: "Cancel",
  CATEGORIES: "Categories",
  CHARTS: "Charts",
  CLOSE_ALERT: "Close alert",
  CLOSE_DIALOG: "Close dialog",
  CLOSE_MODAL: "Close modal",
  CLOUD_MATURITY_ASSESSMENT: "Cloud Maturity Assessment",
  COLOR_ASSESSMENT: "Assessment",
  COLOR_ASSESSMENT_TITLE: "AWS Cloud Maturity Assessment for ",
  COMPLETED_ASSESSMENTS_AND_SNAPSHOTS: "Completed Assessments & Snapshots",
  COMPREHENSIVE_RESOURCE_INFO_1: "Here is the listing of Comprehensive ",
  COMPREHENSIVE_RESOURCE_INFO_2: "AWS Professional Services offerings ",
  COMPREHENSIVE_RESOURCE_INFO_3: "that can help accelerate customer outcomes.",
  CONFIRM: "Confirm",
  CONTEXT: "Context",
  CREATE_ASSESSMENT: "Create Assessment",
  CREATE_SNAPSHOT: "Create snapshot",
  CREATE_SNAPSHOT_FOR: "Create Snapshot for",
  CMA_ASSESSMENT_TYPE: "CSM",
  CSV_FAILURE: "Failure encoding as .csv",
  CUSTOMER_ACCOUNT_NAME: "Customer Account Name",
  DELETE: "Delete",
  DOWNLOAD: "Download",
  DOWNLOAD_AS_CSV: "Download as .csv",
  EDIT_ASSESSMENT_DETAILS: "Edit Assessment details & status",
  ENABLER_DESCRIPTION: "Enabler Description",
  ENABLER_TITLE: "Enabler Title",
  ENTER_NAME_OF_BUSINESS_UNIT: "Enter the name of the Business Unit",
  ERROR_DELETE_ASSESSMENT: "Failed to delete assessment",
  ERROR_INVALID_USERNAME: "Invalid username. Expecting 1-64 lower case username",
  ERROR_SNAPSHOT_ASSESSMENT: "Failed to create snapshot for assessment",
  ERROR_UPDATE_ASSESSMENT: "Failed to update assessment",
  ERROR_UPDATE_ASSESSMENT_RESPONSE: "Failed to update assessment response",
  ERROR_UPDATE_PERMISSION: "Failed to update permission",
  ERRORS_FOUND: "Errors found, please see below.",
  ERRORS_LIST: "Errors List",
  FILE_UPLOAD: "File Upload",
  GENERATE_RADAR_CHART: "Generate Radar Chart",
  GREEN: "Green",
  HERE: "here.",
  HOW_TO_USE: "How to use",
  HOW_TO_USE_RESOURCES_INFO: " represents areas where the customer is mature. Focus on offerings and guidance in areas that are not green.",
  INPUT_FILE: "Input File",
  IS_ASSESSMENT_FOR_BUSINESS_UNIT: "Is the Assessment for a business unit for the selected customer?",
  IS_DELIVERED_REMOTELY: "Check here if delivered remotely.",
  IS_DEMO_INPUT: "This is a demo or test input and not actual customer data. Please do not use this data to generate customer activity.",
  LOADING_RESOURCES: "Loading resources",
  NEXT: "Next",
  NOTES: "Notes",
  OK: "OK",
  OPTIONAL: "Optional",
  PERSPECTIVE: "Perspective",
  PERSPECTIVES: "Perspectives",
  PREVIOUS: "Previous",
  PROCESSING_RESPONSES: "Processing Responses",
  QUESTION: "Question",
  RESOURCE_TABLE_DESCRIPTION: "Below are a list of AWS resources and guidance that can help you build a customized roadmap for your customer.",
  ROADMAP_GUIDANCE: "Roadmap Guidance",
  SHARE_ASSESSMENT_DETAILS: "Share Assessment with other users",
  SHOW_LABELS: "Show labels (Toggle between Summary/Detailed view)",
  SNAPSHOT_ERROR: "Snapshotting error",
  SNAPSHOT_INFO_1: "Snapshots store point in time progress of maturity assessment. Once created, they are available in the reports section and are not editable.",
  SNAPSHOT_INFO_2: "Snapshots can be created only when the status of the assessment is changed to Complete which can be done via the Edit button.",
  SNAPSHOT_INFO_3: "Snapshots can only be deleted if you delete their corresponding assessment",
  STAGE_OF_ADOPTION: "Stage of Adoption",
  STATUS: "Status",
  SUBMIT: "Submit",
  SUMMARY: "Summary",
  TABLE_DEFAULT_PAGE_SIZE: 25,
  TABLE_PAGE_SELECTOR_MESSAGE: "Items per page",
  TABLE_PREFERENCES_TITLE: "Preferences",
  TABLE_WRAPLINES_LABEL: "Wrap lines",
  TABLE_WRAPLINES_DESCRIPTION: "Check to wrap long text so you can see all of it",
  THEME: "Theme",
  THERE_WERE_ERRORS_ON_SUBMISSION: "There were some errors upon submission, please copy these to share with the dev team:",
  QUESTIONNAIRE_CSV_INSTRUCTIONS: "If you completed the assessment in Excel, you can upload the assessment after you convert it to a CSV file. Else you can skip this step to start from blank assessment.",
  QUESTIONNAIRE_CSV_INSTRUCTIONS_1: "The instructions and tool to convert the Excel to CSV file are ",
  QUESTIONNAIRE_CSV_INSTRUCTIONS_2: "If you skip this step, you will need to create a new assessment to be able to upload.",
  UPDATE_ASSESSMENT_INFO: "Update Assessment Info",
  UPLOAD_QUESTIONNAIRE_CSV: "Upload Questionnaire CSV File",
  UPLOAD_QUESTIONNAIRE_RESPONSES: "Upload Questionnaire Responses",
  WITHOUT_AT_SIGN: "without @amazon.com",
}