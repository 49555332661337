import { Box, Button, Grid, Header, Spinner } from '@amzn/awsui-components-react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Radar from 'react-chartjs-2';
import { AppWideContext } from '../../../AppContext';
import { loadTransformedAssessments, TransformedAssessmentResponse } from './LoadAssessmentResponse';
import './RadarCharts.scss';
import { ScoreRollups, getScoreRollups } from '../Utils';
import Constants from '../../common/Constants';
import Container from '@amzn/awsui-components-react/polaris/container/internal';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';



interface Props {
  transformedResponses?: TransformedAssessmentResponse[],
}
/** Following https://www.npmjs.com/package/html-to-image to download charts */
const tryDownload = () => {
  htmlToImage.toPng(document.getElementById("radar-charts")!).then((dataUrl) => {
    download(dataUrl, 'radar-charts.png');
  })
}

const radarOptions =
{
  legend: {
    labels: {
      fontSize: 16
    }
  },
  scale: {
    pointLabels: {
      fontSize: 16,
    },
    ticks: {
      stepSize: 1,
      beginAtZero: true,
      max: 5,
      min: 0,
      suggestedMax: 5,
      suggestedMin: 0,
    }
  }
}
//scales: { ticks: { stepSize: 1, beginAtZero: true, max: 5, suggestedMax: 5, suggestedMin: 0, min: 0 } } }

/**
 * Creates radar charts from assessment question responses
 */
const RadarCharts = (props: Props) => {
  const [scoreRollups, setScoreRollups] = useState<ScoreRollups | null>(null);
  const context = useContext(AppWideContext);
  const history = useHistory();
  const assessmentId: string = history?.location?.pathname?.split("/assessments/")[1]?.split("/")[0];

  useEffect(() => {
    if (props.transformedResponses) {
      setScoreRollups(getScoreRollups(props.transformedResponses));
    } else {
      loadTransformedAssessments(context, assessmentId).then((r) => {
        if (r) {
          const scores = getScoreRollups([r]);
          setScoreRollups(scores);
        }
      });
    }
  }, [context, assessmentId, props.transformedResponses]);

  return (
    <Container>
      <Grid gridDefinition={[{ colspan: { m: 6, xxs: 12 } }, { colspan: { m: 6, xxs: 12 } }]} id="radar-charts">
        <Box>
          <Header>{Constants.PERSPECTIVES}</Header>
          {!scoreRollups && <Spinner />}
          {scoreRollups && scoreRollups.perspective &&
            <Radar
              data={scoreRollups.perspective}
              type="radar"
              options={radarOptions}
            />
          }
        </Box>
        <Box>
          <Header>{Constants.CATEGORIES}</Header>
          {!scoreRollups && <Spinner />}
          {scoreRollups && scoreRollups.category &&
            <Radar
              data={scoreRollups.category}
              type="radar"
              options={radarOptions}
            />
          }
        </Box>
      </Grid>
      <Button variant="primary" onClick={() => tryDownload()} disabled={!scoreRollups}>{Constants.DOWNLOAD}</Button>
    </Container>
  );
}

export default RadarCharts;