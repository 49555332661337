import Constants from "../views/common/Constants";

/**
 * AmazonCorporate_username => username
 */
export const mapUserNameToFrontend = (userName: string) => {
  // safe cast due to the previous check
  let userId = userName.split(Constants.AMAZON_CORPORATE_USER_PREFIX).pop() as string;
  if (userId.includes(":")) {
    // e.g. AmazonCorporate_wilkinsa:wilkinsa
    userId = userId.split(":")[0];
  }
  return userId;
}

/**
 *  username => AmazonCorporate_username
 */
export const mapUserNameToBackend = (userName: string) => {
  if (!userName) {
    throw new Error(`Malformed userName: ${userName}`);
  }
  return `${Constants.AMAZON_CORPORATE_USER_PREFIX}${userName}`;
}

export const capitalizeString = (text: string | null | undefined) => {
  return text ? text[0].toUpperCase() + text?.substring(1, text?.length).toLocaleLowerCase() : '';
}

