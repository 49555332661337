import { AssessmentPrompt, AssessmentResponseRating } from "@amzn/awscat-aws-assessment-service-typescript-client";
import { SelectProps } from "@amzn/awsui-components-react";
import { TransformedAssessmentResponse } from "./LoadAssessmentResponse";

/** Actions permitted in the color assessment page */
export enum ActionName {
    AddNotes,
    ChangeSelection,
    CloseQuestion,
    LoadTemplate,
    SelectQuestion,
    Update,
}

/** States in the color assessment page */
export enum StateName {
    LoadingAssessment_FetchedResults,
    LoadingAssessment_Idle,
    SelectQuestion_Selected,
    SelectQuestion_Updated
}

/** This is a Discriminated Union.  Inspiration: https://dev.to/torbenrahbekkoch/comment/n3oj */
export type Action = {
    actionName: ActionName.AddNotes;
    notes: string;
} | {
    actionName: ActionName.ChangeSelection;
    selectedOption: SelectProps.Option;
} | {
    actionName: ActionName.CloseQuestion;
} | {
    actionName: ActionName.LoadTemplate;
    assessmentResult: TransformedAssessmentResponse | null;
} | {
    actionName: ActionName.SelectQuestion;
    currentCell: AssessmentPrompt | null;
} | {
    actionName: ActionName.Update;
    updatedResponse?: AssessmentResponseRating;
    promptId?: string;
    error?: string;
} 