import { AppContext } from '../../../AppContext';
import { AssessmentStatus, UpdateAssessmentInput } from "@amzn/awscat-aws-assessment-service-typescript-client";

export interface UpdateInfoProps {
    assessmentId: string;
    description: string;
    status: AssessmentStatus;
    isDemoTest: boolean;
    context: AppContext;
}

/**
 * Updates the Assessment Info
 */
const updateAssessmentInfo = async (props: UpdateInfoProps) => {
    const assessmentId = props.assessmentId;
    const updateAssessmentInput: UpdateAssessmentInput = {
        description: props.description,
        isDemoTest: props.isDemoTest,
        status: props.status,
    };

    return props.context.assessmentClient?.updateAssessment(assessmentId, updateAssessmentInput);
}

export default updateAssessmentInfo;