import {
  Alert, Box, Button, ColumnLayout, FormField,
  Input, Modal, SpaceBetween, TextContent
} from '@amzn/awsui-components-react';
import React, { useContext, useState } from 'react';
import { AppWideContext } from '../../AppContext';
import Constants from '../common/Constants';
import { Action, AssessmentViewModel, Kind } from './Assessments';

interface Props {
  assessment: AssessmentViewModel;
  dispatch: React.Dispatch<Action>;
}

const DeleteAssessmentDialog = (props: Props) => {
  interface ComponentState {
    deleteConfirmed: boolean,
    deleteInputText: string,
    deleteInProgress: boolean,
    errorAlertVisible: boolean,
    errorMessage: string,
  }

  const context = useContext(AppWideContext);
  const [state, setState] = useState<ComponentState>({
    deleteConfirmed: false,
    deleteInputText: '',
    deleteInProgress: false,
    errorAlertVisible: false,
    errorMessage: '',
  });

  const deleteItemAsync = async () => {
    try {
      setState({ ...state, deleteInProgress: true, errorAlertVisible: false });
      await context.assessmentClient?.deleteAssessment(props.assessment.id);
      props.dispatch({ kind: Kind.ReloadItems });
    } catch (e) {
      setState({ ...state, deleteInProgress: false, errorAlertVisible: true, errorMessage: `${e}` });
    }
  }

  return (
    <Modal
      visible={true}
      header={`Delete: ${props.assessment.description}?`}
      closeAriaLabel="Close dialog"
      onDismiss={() => props.dispatch && props.dispatch({ kind: Kind.CancelAction })}
      footer={
        < Box float="right" >
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={() => props.dispatch && props.dispatch({ kind: Kind.CancelAction })}>{Constants.CANCEL}</Button>
            <Button variant="primary" loading={state.deleteInProgress} onClick={() => deleteItemAsync()} disabled={!state.deleteConfirmed}>{Constants.DELETE}</Button>
          </SpaceBetween>
        </Box >
      }
    >
      <ColumnLayout borders="horizontal">
        <div>
          <TextContent>
            <p>Delete assessment <strong>{props.assessment.description}</strong> permanently? This action cannot be undone.</p>
          </TextContent>
        </div>
        <Box margin={{ top: 'xxs' }}>
          <FormField label="To confirm deletion, enter 'delete' in the text input field." stretch={true}>
            <Input
              placeholder="delete"
              onChange={event => {
                const value = event.detail.value;
                const isInputValid = (typed: string) => typed.toUpperCase() === 'DELETE';
                isInputValid(value)
                  ? setState({ ...state, deleteConfirmed: true, deleteInputText: value })
                  : setState({ ...state, deleteConfirmed: false, deleteInputText: value });
              }}
              value={state.deleteInputText}
              ariaRequired={true}
            />
          </FormField>
        </Box>
        <Alert
          onDismiss={() => setState({ ...state, errorAlertVisible: false })}
          visible={state.errorAlertVisible}
          dismissAriaLabel={Constants.CLOSE_ALERT}
          dismissible
          type="error"
          header={Constants.ERROR_DELETE_ASSESSMENT}
        >
          {state.errorMessage}
        </Alert>
      </ColumnLayout>
    </Modal >
  );
}

export default DeleteAssessmentDialog