type ActvityRow = {
    activityId: string,
    subRows: (string | null)[][]
}

type Table = {
    activities: ActvityRow[]
}

// A declaritve approach here is adopted due to the dataset not having sufficent information to render entire programatically.
const denormalisedTable: Table = {
    activities: [
        {
            activityId: 'I.PROJECT',
            subRows: [
                [
                    'people-education-0',
                    'people-organizationalEnablement-0',
                    'people-partneringAndProServe-0',
                    'governance-governance-0',
                    'business-migrationsDevelopment-0',
                    'business-commercial-0',
                    'business-strategicAlignment-0',
                    'platform-platform-0',
                    'security-security-0',
                    'operations-operationsAndSupport-0',
                    'operations-costOptimization-0'
                ], // row 1
                [
                    'people-education-1',
                    'people-organizationalEnablement-1',
                    null,
                    'governance-governance-1',
                    'business-migrationsDevelopment-1',
                    null,
                    'business-strategicAlignment-1',
                    'platform-platform-1',
                    'security-security-1',
                    'operations-operationsAndSupport-1',
                    null
                ], //row 2
                [
                    null,
                    null,
                    null,
                    'governance-governance-2',
                    'business-migrationsDevelopment-2',
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ], // row3
                [
                    null,
                    null,
                    null,
                    'governance-governance-3',
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ],
            ]
        },
        {
            activityId: 'II.FOUNDATION',
            subRows: [
                [
                    'people-education-2',
                    'people-organizationalEnablement-2',
                    'people-partneringAndProServe-1',
                    'governance-governance-4',
                    'business-migrationsDevelopment-3',
                    'business-commercial-1',
                    'business-strategicAlignment-2',
                    'platform-platform-2',
                    'security-security-2',
                    'operations-operationsAndSupport-2',
                    'operations-costOptimization-1'
                ], // row 1
                [
                    'people-education-3',
                    'people-organizationalEnablement-3',
                    null,
                    'governance-governance-5',
                    'business-migrationsDevelopment-4',
                    'business-commercial-2',
                    'business-strategicAlignment-3',
                    'platform-platform-3',
                    'security-security-3',
                    'operations-operationsAndSupport-3',
                    'operations-costOptimization-2'
                ], //row 2
                [
                    'people-education-4',
                    'people-organizationalEnablement-4',
                    null,
                    'governance-governance-6',
                    'business-migrationsDevelopment-5',
                    null,
                    'business-strategicAlignment-4',
                    'platform-platform-4',
                    'security-security-4',
                    'operations-operationsAndSupport-4',
                    'operations-costOptimization-3'
                ], // row3
                [
                    null,
                    null,
                    null,
                    'governance-governance-7',
                    'business-migrationsDevelopment-6',
                    null,
                    'business-strategicAlignment-5',
                    'platform-platform-5',
                    'security-security-5',
                    'operations-operationsAndSupport-5',
                    null
                ], //row 4
                [
                    null,
                    null,
                    null,
                    'governance-governance-8',
                    null,
                    null,
                    null,
                    'platform-platform-6',
                    'security-security-6',
                    'operations-operationsAndSupport-6',
                    null
                ], //row 5
                [
                    null,
                    null,
                    null,
                    'governance-governance-9',
                    null,
                    null,
                    null,
                    'platform-platform-7',
                    'security-security-7',
                    null,
                    null
                ], // row 6 
                [
                    null,
                    null,
                    null,
                    'governance-governance-10',
                    null,
                    null,
                    null,
                    'platform-platform-8',
                    'security-security-8',
                    null,
                    null
                ] // row 7
            ]
        },
        {
            activityId: 'III. ACCELERATION & MIGRATION',
            subRows: [
                [
                    'people-education-5',
                    'people-organizationalEnablement-5',
                    'people-partneringAndProServe-2',
                    'governance-governance-11',
                    'business-migrationsDevelopment-7',
                    'business-commercial-3',
                    'business-strategicAlignment-6',
                    'platform-platform-9',
                    'security-security-9',
                    'operations-operationsAndSupport-7',
                    'operations-costOptimization-4'
                ], // row 1
                [
                    'people-education-6',
                    'people-organizationalEnablement-6',
                    null,
                    'governance-governance-12',
                    'business-migrationsDevelopment-8',
                    'business-commercial-4',
                    'business-strategicAlignment-7',
                    'platform-platform-10',
                    'security-security-10',
                    'operations-operationsAndSupport-8',
                    'operations-costOptimization-5'
                ], //row 2
                [
                    'people-education-7',
                    'people-organizationalEnablement-7',
                    null,
                    'governance-governance-13',
                    'business-migrationsDevelopment-9',
                    'business-commercial-5',
                    'business-strategicAlignment-8',
                    'platform-platform-11',
                    'security-security-11',
                    'operations-operationsAndSupport-9',
                    'operations-costOptimization-6'
                ], // row3
                [
                    null,
                    'people-organizationalEnablement-8',
                    null,
                    'governance-governance-14',
                    'business-migrationsDevelopment-10',
                    null,
                    'business-strategicAlignment-9',
                    null,
                    'security-security-12',
                    'operations-operationsAndSupport-11',
                    'operations-costOptimization-7'
                ], //row 4
                [
                    null,
                    null,
                    null,
                    'governance-governance-15',
                    'business-migrationsDevelopment-11',
                    null,
                    null,
                    null,
                    'security-security-13',
                    'operations-operationsAndSupport-12',
                    'operations-costOptimization-8'
                ], //row 5
                [
                    null,
                    null,
                    null,
                    'governance-governance-16',
                    'business-migrationsDevelopment-12',
                    null,
                    null,
                    null,
                    'security-security-14',
                    'operations-operationsAndSupport-13',
                    null
                ], // row 6 
                [
                    null,
                    null,
                    null,
                    'governance-governance-17',
                    'business-migrationsDevelopment-13',
                    null,
                    null,
                    null,
                    null,
                    'operations-operationsAndSupport-14',
                    null
                ], // row 7
                [
                    null,
                    null,
                    null,
                    'governance-governance-18',
                    'business-migrationsDevelopment-14',
                    null,
                    null,
                    null,
                    null,
                    null,
                    null
                ]
            ]
        },
        {
            activityId: 'IV. OPTIMIZATION & REINVENTION',
            subRows: [
                [
                    'people-education-8',
                    'people-organizationalEnablement-9',
                    'people-partneringAndProServe-3',
                    'governance-governance-19',
                    'business-migrationsDevelopment-15',
                    'business-commercial-6',
                    'business-strategicAlignment-10',
                    'platform-platform-12',
                    'security-security-15',
                    'operations-operationsAndSupport-15',
                    'operations-costOptimization-9'
                ], // row 1
                [
                    'people-education-9',
                    'people-organizationalEnablement-10',
                    null,
                    'governance-governance-20',
                    'business-migrationsDevelopment-16',
                    null,
                    'business-strategicAlignment-11',
                    'platform-platform-13',
                    'security-security-16',
                    'operations-operationsAndSupport-16',
                    'operations-costOptimization-10'
                ], //row 2
                [
                    'people-education-10',
                    'people-organizationalEnablement-11',
                    null,
                    'governance-governance-21',
                    'business-migrationsDevelopment-17',
                    null,
                    'business-strategicAlignment-12',
                    'platform-platform-14',
                    'security-security-17',
                    'operations-operationsAndSupport-17',
                    'operations-costOptimization-11'
                ], // row3
                [
                    'people-education-11',
                    null,
                    null,
                    'governance-governance-22',
                    'business-migrationsDevelopment-18',
                    null,
                    'business-strategicAlignment-13',
                    null,
                    'security-security-18',
                    'operations-operationsAndSupport-18',
                    'operations-costOptimization-12'
                ], //row 4
                [
                    'people-education-12',
                    null,
                    null,
                    'governance-governance-23',
                    'business-migrationsDevelopment-19',
                    null,
                    'business-strategicAlignment-14',
                    null,
                    'security-security-19',
                    'operations-operationsAndSupport-19',
                    null
                ], //row 5
                [
                    null,
                    null,
                    null,
                    'governance-governance-24',
                    null,
                    null,
                    null,
                    null,
                    null,
                    'operations-operationsAndSupport-20',
                    null
                ], // row 6 
                [
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    'operations-operationsAndSupport-21',
                    null
                ], // row 7
            ]
        },
        {
            activityId: 'V. DISRUPT',
            subRows: [
                [
                    'people-education-13',
                    'people-organizationalEnablement-12',
                    'people-partneringAndProServe-4',
                    'governance-governance-25',
                    'business-migrationsDevelopment-20',
                    'business-commercial-7',
                    'business-strategicAlignment-15',
                    'platform-platform-15',
                    'security-security-20',
                    'operations-operationsAndSupport-22',
                    'operations-costOptimization-13'
                ], // row 1
                [
                    'people-education-14',
                    null,
                    null,
                    'governance-governance-26',
                    'business-migrationsDevelopment-21',
                    null,
                    'business-strategicAlignment-16',
                    null,
                    null,
                    'operations-operationsAndSupport-23',
                    null
                ], //row 2
                [
                    'people-education-15',
                    null,
                    null,
                    'governance-governance-27',
                    'business-migrationsDevelopment-22',
                    null,
                    'business-strategicAlignment-17',
                    null,
                    null,
                    'operations-operationsAndSupport-24',
                    null
                ], // row3
                [
                    null,
                    null,
                    null,
                    null,
                    'business-migrationsDevelopment-23',
                    null,
                    'business-strategicAlignment-18',
                    null,
                    null,
                    'operations-operationsAndSupport-25',
                    null
                ], //row 4
                [
                    null,
                    null,
                    null,
                    null,
                    'business-migrationsDevelopment-24',
                    null,
                    null,
                    null,
                    null,
                    'operations-operationsAndSupport-26',
                    null
                ]
            ]
        }
    ]
}

export default denormalisedTable;