
type ActvityRow = {
  activityId: string,
  promptIdGroups: (string | null)[][]
}

type Table = {
  activities: ActvityRow[]
}

const progressTable: Table = {
  activities: [
    {
      activityId: 'I.PROJECT',
      promptIdGroups: [
        [
          'people-education-0',
          'people-education-1',
        ],
        [
          'people-organizationalEnablement-0',
          'people-organizationalEnablement-1',
        ],
        [
          'people-partneringAndProServe-0',
        ],
        [
          'governance-governance-0',
          'governance-governance-1',
          'governance-governance-2',
          'governance-governance-3',
        ],
        [
          'business-migrationsDevelopment-0',
          'business-migrationsDevelopment-1',
          'business-migrationsDevelopment-2',
        ],
        [
          'business-commercial-0',
        ],
        [
          'business-strategicAlignment-0',
          'business-strategicAlignment-1',
        ],
        [
          'platform-platform-0',
          'platform-platform-1',
        ],
        [
          'security-security-0',
          'security-security-1',
        ],
        [
          'operations-operationsAndSupport-0',
          'operations-operationsAndSupport-1',
        ],
        [
          'operations-costOptimization-0'
        ],
      ]
    },
    {
      activityId: 'II.FOUNDATION',
      promptIdGroups: [
        [
          'people-education-2',
          'people-education-3',
          'people-education-4',
        ],
        [
          'people-organizationalEnablement-2',
          'people-organizationalEnablement-3',
          'people-organizationalEnablement-4',
        ],
        [
          'people-partneringAndProServe-1',
        ],
        [
          'governance-governance-4',
          'governance-governance-5',
          'governance-governance-6',
          'governance-governance-7',
          'governance-governance-8',
          'governance-governance-9',
          'governance-governance-10',
        ],
        [
          'business-migrationsDevelopment-3',
          'business-migrationsDevelopment-4',
          'business-migrationsDevelopment-5',
          'business-migrationsDevelopment-6',
        ],
        [
          'business-commercial-1',
          'business-commercial-2',
        ],
        [
          'business-strategicAlignment-2',
          'business-strategicAlignment-3',
          'business-strategicAlignment-4',
          'business-strategicAlignment-5',
        ],
        [
          'platform-platform-2',
          'platform-platform-3',
          'platform-platform-4',
          'platform-platform-5',
          'platform-platform-6',
          'platform-platform-7',
          'platform-platform-8',
        ],
        [
          'security-security-2',
          'security-security-3',
          'security-security-4',
          'security-security-5',
          'security-security-6',
          'security-security-7',
          'security-security-8',
        ],
        [
          'operations-operationsAndSupport-2',
          'operations-operationsAndSupport-3',
          'operations-operationsAndSupport-4',
          'operations-operationsAndSupport-5',
          'operations-operationsAndSupport-6',
        ],
        [
          'operations-costOptimization-1',
          'operations-costOptimization-2',
          'operations-costOptimization-3'
        ],
      ]
    },
    {
      activityId: 'III. ACCELERATION & MIGRATION',
      promptIdGroups: [
        [
          'people-education-5',
          'people-education-6',
          'people-education-7',
        ],
        [
          'people-organizationalEnablement-5',
          'people-organizationalEnablement-6',
          'people-organizationalEnablement-7',
          'people-organizationalEnablement-8',
        ],
        [
          'people-partneringAndProServe-2',
        ],
        [
          'governance-governance-11',
          'governance-governance-12',
          'governance-governance-13',
          'governance-governance-14',
          'governance-governance-15',
          'governance-governance-16',
          'governance-governance-17',
          'governance-governance-18',
        ],
        [
          'business-migrationsDevelopment-7',
          'business-migrationsDevelopment-8',
          'business-migrationsDevelopment-9',
          'business-migrationsDevelopment-10',
          'business-migrationsDevelopment-11',
          'business-migrationsDevelopment-12',
          'business-migrationsDevelopment-13',
          'business-migrationsDevelopment-14',
        ],
        [
          'business-commercial-3',
          'business-commercial-4',
          'business-commercial-5',
        ],
        [
          'business-strategicAlignment-6',
          'business-strategicAlignment-7',
          'business-strategicAlignment-8',
          'business-strategicAlignment-9',
        ],
        [
          'platform-platform-9',
          'platform-platform-10',
          'platform-platform-11',
        ],
        [
          'security-security-9',
          'security-security-10',
          'security-security-11',
          'security-security-12',
          'security-security-13',
          'security-security-14',
        ],
        [
          'operations-operationsAndSupport-7',
          'operations-operationsAndSupport-8',
          'operations-operationsAndSupport-9',
          'operations-operationsAndSupport-11',
          'operations-operationsAndSupport-12',
          'operations-operationsAndSupport-13',
          'operations-operationsAndSupport-14',
        ],
        [
          'operations-costOptimization-4',
          'operations-costOptimization-5',
          'operations-costOptimization-6',
          'operations-costOptimization-7',
          'operations-costOptimization-8',
        ],
      ]
    },
    {
      activityId: 'IV. OPTIMIZATION & REINVENTION',
      promptIdGroups: [
        [
          'people-education-8',
          'people-education-9',
          'people-education-10',
          'people-education-11',
          'people-education-12',
        ],
        [
          'people-organizationalEnablement-9',
          'people-organizationalEnablement-10',
          'people-organizationalEnablement-11',
        ],
        [
          'people-partneringAndProServe-3',
        ],
        [
          'governance-governance-19',
          'governance-governance-20',
          'governance-governance-21',
          'governance-governance-22',
          'governance-governance-23',
          'governance-governance-24',
        ],
        [
          'business-migrationsDevelopment-15',
          'business-migrationsDevelopment-16',
          'business-migrationsDevelopment-17',
          'business-migrationsDevelopment-18',
          'business-migrationsDevelopment-19',
        ],
        [
          'business-commercial-6',
        ],
        [
          'business-strategicAlignment-10',
          'business-strategicAlignment-11',
          'business-strategicAlignment-12',
          'business-strategicAlignment-13',
          'business-strategicAlignment-14',
        ],
        [
          'platform-platform-12',
          'platform-platform-13',
          'platform-platform-14',
        ],
        [
          'security-security-15',
          'security-security-16',
          'security-security-17',
          'security-security-18',
          'security-security-19',
        ],
        [
          'operations-operationsAndSupport-15',
          'operations-operationsAndSupport-16',
          'operations-operationsAndSupport-17',
          'operations-operationsAndSupport-18',
          'operations-operationsAndSupport-19',
          'operations-operationsAndSupport-20',
          'operations-operationsAndSupport-21',
        ],
        [
          'operations-costOptimization-9',
          'operations-costOptimization-10',
          'operations-costOptimization-11',
          'operations-costOptimization-12'
        ],
      ]
    },
    {
      activityId: 'V. DISRUPT',
      promptIdGroups: [
        [
          'people-education-13',
          'people-education-14',
          'people-education-15',
        ],
        [
          'people-organizationalEnablement-12',
        ],
        [
          'people-partneringAndProServe-4',
        ],
        [
          'governance-governance-25',
          'governance-governance-26',
          'governance-governance-27',
        ],
        [
          'business-migrationsDevelopment-20',
          'business-migrationsDevelopment-21',
          'business-migrationsDevelopment-22',
          'business-migrationsDevelopment-23',
          'business-migrationsDevelopment-24',
        ],
        [
          'business-commercial-7',
        ],
        [
          'business-strategicAlignment-15',
          'business-strategicAlignment-16',
          'business-strategicAlignment-17',
          'business-strategicAlignment-18',
        ],
        [
          'platform-platform-15',
        ],
        [
          'security-security-20',
        ],
        [
          'operations-operationsAndSupport-22',
          'operations-operationsAndSupport-23',
          'operations-operationsAndSupport-24',
          'operations-operationsAndSupport-25',
          'operations-operationsAndSupport-26',
        ],
        [
          'operations-costOptimization-13'
        ],
      ]
    }
  ]
}

export default progressTable;