import { AssessmentResponseRating, CreateOrUpdateAssessmentResponseInput, CreateOrUpdateAssessmentResponsesInput } from "@amzn/awscat-aws-assessment-service-typescript-client";
import { PromptResponseProperty, getPromptResponseProperty } from "../Utils";
import { Action, ActionName } from "./ColorAssesssmentActions";
import { ViewState } from "./ColorAssessment";
import Constants from "../../common/Constants";

export interface UpdateQuestionProp {
    dispatch: React.Dispatch<Action>;
    state: ViewState
}

/**
 * Updates the user response for the question in the backend.
 * Dispatches the action to update the assessmentResult in the
 * state to reflect the update in the tale content.
 * 
 * @param props containing the state and dispatch action.
 */
export const updateQuestion = (props: UpdateQuestionProp, setUpdateInprogress: React.Dispatch<React.SetStateAction<boolean>>) => {
    const state: ViewState = props.state;

    if (state.currentCell !== null) {
        let updatedResponse: AssessmentResponseRating;

        const comments: string | null = state.notes ? state.notes : null
        const rating: number = state.selectedOption?.value ?
            +state.selectedOption.value
            : getPromptResponseProperty(
                state.currentCell,
                PromptResponseProperty.StatusValue) as number;
        const promptId: string = state.currentCell.id

        const response: CreateOrUpdateAssessmentResponseInput = {
            promptId: promptId,
            rating: rating,
            comments: comments
        }

        const input: CreateOrUpdateAssessmentResponsesInput = {
            assessmentId: state.assessmentId,
            responses: [response]
        };
        try {
            props.state.context.assessmentClient?.createOrUpdateAssessmentResponses(input).then((promptResponse) => {
                setUpdateInprogress(false);
                if (promptResponse.errors) {
                    props.dispatch({
                        actionName: ActionName.Update,
                        error: Constants.ERROR_UPDATE_ASSESSMENT_RESPONSE,
                    });
                    return;
                }
                updatedResponse = {
                    comments: comments,
                    intValue: rating
                } as AssessmentResponseRating
                props.dispatch({
                    actionName: ActionName.Update,
                    updatedResponse: updatedResponse,
                    promptId: promptId
                })
            })
        } catch (e) {
            setUpdateInprogress(false);
            props.dispatch({
                actionName: ActionName.Update,
                error: "Error occured during updation"
            });
        }
    }
}