export const ShortbreadRegistry = {
    "CognitoIdentityServiceProvider:*": {
        category: "essential",
    },
    "amplify-redirected-from-hosted-ui": {
        category: "essential",
    },
    "amplify-signin-with-hostedUI": {
        category: "essential",
    },
    "CloudFront-*": {
        category: "essential",
    },
};
