import { Assessment } from "@amzn/awscat-aws-assessment-service-typescript-client";
import { Container, SpaceBetween, Spinner, Alert, Link } from "@amzn/awsui-components-react";
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AppWideContext } from "../../../AppContext";
import { loadAssessmentAndTransformedAssessment, TransformedAssessmentResponse } from "./LoadAssessmentResponse";
import { getHeader, getSubHeader, getActionRows } from "./TableContent";
import './Resources.scss';
import Constants from "../../common/Constants";


const Resources = () => {
  const [assessment, setAssessment] = useState<Assessment | null>(null);
  const [transformedAssessment, setTransformedAssessment] = useState<TransformedAssessmentResponse | null>(null);
  const context = useContext(AppWideContext)
  const history = useHistory();

  const assessmentId: string = history?.location?.pathname?.split("/assessments/")[1]?.split("/")[0];

  useEffect(() => {
    loadAssessmentAndTransformedAssessment(context, assessmentId).then((r) => {
      setAssessment(r?.assessment ? r.assessment : null);
      setTransformedAssessment(r?.transformed ? r.transformed : null);
    });
  }, [context, assessmentId]);

  return (
    <Container className="horizontal-sroll">
      {!assessment && <Spinner />}
      {assessment && transformedAssessment &&
        <SpaceBetween direction="vertical" size="s">
          <Alert>{Constants.RESOURCE_TABLE_DESCRIPTION}</Alert>
          <table style={{ scrollMarginTop: 'auto' }}>
            <tbody>
              {getHeader(transformedAssessment?.sectionCategoryMap)}
              {getSubHeader(transformedAssessment?.sectionCategoryMap)}
              {getActionRows(transformedAssessment, assessment)}
            </tbody>
          </table>
          <Alert header={Constants.HOW_TO_USE + ":"}>
            <span className="resource-green">{Constants.GREEN}</span>{Constants.HOW_TO_USE_RESOURCES_INFO}
            <p>{Constants.COMPREHENSIVE_RESOURCE_INFO_1}<Link target="_blank" href="https://pmt.amazonaws.com/">{Constants.COMPREHENSIVE_RESOURCE_INFO_2}</Link>{Constants.COMPREHENSIVE_RESOURCE_INFO_3}</p>
          </Alert>
        </SpaceBetween>
      }
    </Container>
  );
}

export default Resources;