import { CookieConsentManager } from "@amzn/awscat-cookie-management";
import { ShortbreadRegistry } from "./CookieRegistry";
/*
 * Shortbread Integration for EU Cookie compliance: https://w.amazon.com/bin/view/Shortbread/usage
 *
 * It will only be invoked when shortbread's geoip location detects traffic from EU.
 * For testing, use AnyConnect VPN and connect to an EU endoint such as 'Frankfurt, DE', and
 */
class CookieManager {
    public static create(): CookieConsentManager {
        if (!this.cookieManager) {
        this.cookieManager = new CookieConsentManager({
            domain: window.location.hostname,
            language: window.navigator.language as any,
            registry: ShortbreadRegistry as any,
        });
        }
        return this.cookieManager;
    }
    private static cookieManager: CookieConsentManager;
}

export default CookieManager;
