import { Environments as BackendEnvironments, IEnvironment } from '@amzn/awscat-aws-assessment-service-typescript-client/dist/Environments';

export type FrontendEnvironmentName = 'dev' | 'beta' | 'gamma' | 'prod';

export type FrontendEnvironment = {
  region: 'us-west-2';
  webClientId: string;
  cognitoDomain: string;
  backendDomain: 'beta' | 'prod' | 'gamma';
  backendEnvironment: IEnvironment;
}

type Environments = {
  dev: FrontendEnvironment,
  beta: FrontendEnvironment,
  gamma: FrontendEnvironment,
  prod: FrontendEnvironment,
}

const environmentMap: Environments = {
  dev: {
    // Dev is using Beta backend normally
    region: 'us-west-2',
    webClientId: '38vqjuneh6su53b69mlpmupi8s', // cat-users:cma-localdev
    cognitoDomain: '714c407a.auth.us-west-2.amazoncognito.com',
    backendDomain: 'beta',
    backendEnvironment: BackendEnvironments.getEnvironment('beta', 'us-west-2'),
  },
  beta: {
    region: 'us-west-2',
    webClientId: '3stg2sehsmjknicd67sl5jl1fe', // cat-users:cma
    cognitoDomain: '714c407a.auth.us-west-2.amazoncognito.com',
    backendDomain: 'beta',
    backendEnvironment: BackendEnvironments.getEnvironment('beta', 'us-west-2'),
  },
  gamma: {
    region: 'us-west-2',
    webClientId: '18kh9jelvmiekbl0iu44hlg8uc', // cat-users:cma
    cognitoDomain: '98a38373.auth.us-west-2.amazoncognito.com',
    backendDomain: 'gamma',
    backendEnvironment: BackendEnvironments.getEnvironment('gamma', 'us-west-2'),
  },
  prod: {
    region: 'us-west-2',
    webClientId: '1ag1ev5ovdi67pmot1388iog08', // cat-users:cma
    cognitoDomain: 'e5bb1fc1.auth.us-west-2.amazoncognito.com',
    backendDomain: 'prod',
    backendEnvironment: BackendEnvironments.getEnvironment('prod', 'us-west-2'),
  }
}

export const determineEnvironment = (): {
  frontendEnvName: FrontendEnvironmentName,
  frontendEnv: FrontendEnvironment
} => {
  switch (window.location.hostname) {
    case 'beta.cma.accelerate.proserve.aws.dev':
      return { frontendEnvName: 'beta', frontendEnv: environmentMap.beta };
    case 'gamma.cma.accelerate.proserve.aws.dev':
      return { frontendEnvName: 'gamma', frontendEnv: environmentMap.gamma };
    case 'cma.accelerate.amazonaws.com':
      return { frontendEnvName: 'prod', frontendEnv: environmentMap.prod };
    default:
    // eslint-disable-next-line
    case 'localhost':
      return { frontendEnvName: 'dev', frontendEnv: environmentMap.dev };
  }
}
