import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Icon,
  Link,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import flywheel from './CMM_Flywheel.png';
import './Welcome.scss';



const Welcome = () => {
  return (
    <SpaceBetween size={'xxxs'}>
      <Alert
        type='error'
        statusIconAriaLabel="Warning"
        header={
          <TextContent><h2>This tool for CMA 1.0 will be deprecated in 2023. For creating new assessments please use CMA 3.0. Click <a href='https://a2t.accelerate.amazonaws.com/assessments/create' target='_blank' rel='noopener noreferrer'>here</a> to go to CMA 3.0 </h2></TextContent>
        }
      >
        <SpaceBetween direction='horizontal' size={'xs'}>
          <TextContent><h4>You will be able to view and edit existing assessments through this tool through 2023, but will not be able create new assessments.
            Click <a href='https://w.amazon.com/bin/view/Cloud_Maturity_Assessment/' target='_blank' rel='noopener noreferrer'>here</a> for more information on CMA 3.0</h4></TextContent>
        </SpaceBetween>
      </Alert>
      <Box margin={{ bottom: 'l' }} padding="xs">
        <Grid gridDefinition={[{ colspan: { xxs: 12 } }]} className="custom-home__header">
          <Box padding={{ vertical: 'xxl' }}>
            <Grid
              gridDefinition={[
                { colspan: { l: 11 }, offset: { xxs: 1 } },
                { colspan: { l: 6, xxs: 10 }, offset: { xxs: 1 } },
                { colspan: { l: 2, xs: 6, xxs: 10 }, offset: { xxs: 1 } }
              ]}
            >
              <Box fontWeight="light" padding={{ top: 'xs' }}>
                <span className="custom-home__category">Management Tools</span>
              </Box>
              <div className="custom-home__header-title">
                <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
                  AWS Cloud Maturity Assessment
                </Box>
                <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                  Measure, assess and accelerate the journey to the cloud
                </Box>
                <Box variant="p" fontWeight="light">
                  <span className="custom-home__header-sub-title">
                    The AWS Cloud Maturity Assessment is a Maturity model that tracks the adoption of the customers cloud
                    journey over time. This serves as a tool for customers to assess the maturity of their cloud adoption
                    through five key stages: Project, foundation, acceleration &amp; migration, optimization &amp; reinvention, and
                    disruption. Each stage brings an organization’s people, processes, and technologies closer to realizing its
                    vision for transformation
                  </span>
                </Box>
              </div>
              <Container>
                <SpaceBetween size="xl">
                  <Box variant="h2" padding="n">
                    Complete a Cloud Maturity Assessment
                  </Box>
                  <Button href="https://a2t.accelerate.amazonaws.com/assessments/create" variant="primary" target="_blank">
                    Create CMA 3.0 Assessment
                  </Button>
                </SpaceBetween>
              </Container>
            </Grid>
          </Box>
        </Grid>

        <Box margin={{ top: 'm' }} padding={{ top: 'xxl' }}>
          <Grid
            gridDefinition={[
              { colspan: { l: 6, xxs: 10 }, offset: { xxs: 1 } },
              { colspan: { l: 2, xxs: 10 }, offset: { xxs: 1 } }
            ]}
          >
            <div className="custom-home-main-content-area">
              <SpaceBetween size="l">
                <div>
                  <Header variant="h1" headingTagOverride="h2">
                    Cloud Maturity Assessment Flywheel
                  </Header>
                  <Container>
                    <img className="custom-fit-container" src={flywheel} alt="CMM Flywheel" aria-hidden="true" />
                  </Container>
                </div>

                <div>
                  <Header variant="h1" headingTagOverride="h2">
                    Benefits and features
                  </Header>
                  <Container>
                    <ColumnLayout columns={3} variant="text-grid">
                      <div>
                        <Box variant="h3" padding={{ top: 'n' }}>
                          The Assessment
                        </Box>
                        <Box variant="p">
                          The Cloud Maturity Assessment has been created through our extensive experience with enterprises adopting to cloud, to provide a programmatic framework for customers and AWS to partner around to
                        </Box>
                        <ol>
                          <li>
                            Accelerate enablement activities
                          </li>
                          <li>
                            Establish the organisation, processes and governance required, based on the stage of adoption
                          </li>
                          <li>
                            Ensure adoption of AWS is successful in achieving the businesses goals
                          </li>
                        </ol>
                      </div>
                      <div>
                        <Box variant="h3" padding={{ top: 'n' }}>
                          Enable consistent governance
                        </Box>
                        <Box variant="p">
                          Apply a consistent process to help you review and measure cloud adoption and maturity. Understand
                          gaps in adopting cloud efficiently and use the results of the assessment to identify next steps for
                          improvement.
                        </Box>
                      </div>
                      <div>
                        <Box variant="h3" padding={{ top: 'n' }}>
                          Continuously improve
                        </Box>
                        <Box variant="p">
                          Support tracking over time at business unit level or enterprise level to measure progress and be able to
                          generate dashboards that show improvements and gaps across the enterprise
                        </Box>
                      </div>
                    </ColumnLayout>
                  </Container>
                </div>
              </SpaceBetween>
            </div>
            <div className="custom-home__sidebar">
              <SpaceBetween size="xxl">

                <Container
                  header={
                    <Header variant="h2">
                      Getting started <Icon name="external" />
                    </Header>
                  }
                >
                  <ul aria-label="Getting started documentation" className="custom-list-separator">
                    <li>
                      <Link
                        href="https://w.amazon.com/bin/view/AWS_ETA/ETACommunity/Topics/CustomerSuccessMatrix"
                        target="_blank"
                      >
                        What is AWS Cloud Maturity Assessment
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://w.amazon.com/bin/view/Cloud_Maturity_Assessment/training"
                        target="_blank"
                      >
                        How to Videos
                      </Link>
                    </li>
                  </ul>
                </Container>

                <Container
                  header={
                    <Header variant="h2">
                      More resources <Icon name="external" />
                    </Header>
                  }
                >
                  <ul aria-label="Additional resource links" className="custom-list-separator">
                    <li>
                      <Link
                        href="https://w.amazon.com/bin/view/Cloud_Maturity_Assessment/faq"
                        target="_blank"
                      >
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link
                        href="https://w.amazon.com/bin/view/Cloud_Maturity_Assessment/training"
                        target="_blank"
                      >
                        Training
                      </Link>
                    </li>
                  </ul>
                </Container>
              </SpaceBetween>
            </div>
          </Grid>
        </Box>
      </Box>
    </SpaceBetween>
  );
};


export default Welcome;
