import { Action, ActionName, StateName } from './ColorAssesssmentActions';
import { AppContext, AppWideContext } from '../../../AppContext';
import { AssessmentPrompt } from '@amzn/awscat-aws-assessment-service-typescript-client';
import { Container, Grid, Header, Link, SelectProps, SpaceBetween, Spinner } from '@amzn/awsui-components-react';
import { ColorAssessmentDialog } from './ColorAssessmentDialog';
import { loadTransformedAssessments, TransformedAssessmentResponse, } from './LoadAssessmentResponse';
import { getHeader, getRows, getSubHeader } from './TableContent';
import { getStatusLabel } from './StatusTags';
import Legend from './Legends';
import { PromptResponseProperty, getPromptResponseProperty } from '../Utils';
import React, { useContext, useEffect, useReducer } from 'react'
import { useHistory } from 'react-router-dom';
import Constants from '../../common/Constants';


interface Props {
}

export interface ViewState {
  assessmentId: string;
  assessmentResult: TransformedAssessmentResponse | null;
  context: AppContext;
  currentCell: AssessmentPrompt | null
  currentState: StateName;
  dialogVisible: boolean;
  notes: string;
  selectedOption: SelectProps.Option;
  updateError: string;
}

/**
 * This reducer covers the entire color assessment's state transitions.
 * Based on the user action, the reducer manages the state.
 * @param state 
 * @param action 
 */
const reducer = (state: ViewState, action: Action): ViewState => {
  switch (action.actionName) {
    case ActionName.LoadTemplate:
      if (action.assessmentResult && action.assessmentResult.promptIdMap) {
        return {
          ...state,
          assessmentResult: action.assessmentResult,
          currentState: StateName.LoadingAssessment_FetchedResults
        };
      }
      break;
    case ActionName.SelectQuestion:
      if (action.currentCell) {
        const notes: string = getPromptResponseProperty(
          action.currentCell,
          PromptResponseProperty.Comments) as string;
        const selectedOption: SelectProps.Option = {
          value: getPromptResponseProperty(action.currentCell, PromptResponseProperty.StatusValue).toString(),
          label: getPromptResponseProperty(action.currentCell, PromptResponseProperty.StatusLabel) as string
        };
        return {
          ...state,
          currentCell: action.currentCell,
          currentState: StateName.SelectQuestion_Selected,
          dialogVisible: true,
          notes,
          selectedOption,
        };
      }
      break;
    case ActionName.ChangeSelection:
      return {
        ...state,
        selectedOption: action.selectedOption,
        updateError: '',
      };

    case ActionName.AddNotes:
      return {
        ...state,
        notes: action.notes,
        updateError: '',
      };
    case ActionName.CloseQuestion:
      if (state.currentState === StateName.SelectQuestion_Selected) {
        return {
          ...state,
          currentState: StateName.SelectQuestion_Updated,
          dialogVisible: false,
          updateError: ''
        };
      }
      break;
    case ActionName.Update:
      if (state.currentState === StateName.SelectQuestion_Selected) {
        if (action.updatedResponse && action.promptId) {
          const promptIdMap = state.assessmentResult?.promptIdMap;
          const prompt = promptIdMap?.get(action.promptId);
          if (promptIdMap && prompt) {
            prompt.response = action.updatedResponse
          }
          return {
            ...state,
            currentState: StateName.SelectQuestion_Updated,
            dialogVisible: false,
            updateError: ''
          };
        } else if (action.error) {
          return {
            ...state,
            updateError: action.error
          }
        }
      }
      break;
  }
  return state;
}

/** This model represents the color page for assessments
 * @param props
 */
const ColorAssessment = (props: Props) => {
  const context = useContext(AppWideContext)
  const history = useHistory();
  // pathname is of the form : */assessments/<assessmenttId>/edit
  let assessmentId: string = history?.location?.pathname?.split("/assessments/")[1]?.split("/")[0];

  const [state, dispatch] = useReducer(reducer, {
    assessmentResult: null,
    assessmentId: assessmentId,
    context: context,
    currentCell: null,
    currentState: StateName.LoadingAssessment_Idle,
    dialogVisible: false,
    notes: '',
    selectedOption: {
      value: '0',
      label: getStatusLabel(0)
    },
    updateError: ''
  });

  useEffect(() => {
    loadTransformedAssessments(state.context, state.assessmentId).then((assessmentResult) => {
      dispatch({ actionName: ActionName.LoadTemplate, assessmentResult: assessmentResult })
    })
  }, [state.context, state.assessmentId])


  return (
    <SpaceBetween size="m">
      <Container header={
        <Header variant="h1">
          {Constants.COLOR_ASSESSMENT_TITLE}{state.assessmentResult?.description}
        </Header>}
      >
        <Grid
          gridDefinition={[
            { colspan: { default: 12, xxs: 6 } },
            { colspan: { default: 12, xxs: 6 } }
          ]}
        >
          <SpaceBetween size='xxs'>
            <Header variant="h2">
              Data Entry
            </Header>
            <p>
              To get started, click the appropriate cell and answer the question in the popup.
              You will be able to set the status of the task in the dropdown menu
              and add notes to track your progress.
            </p>
            <p>
              {Constants.ASSESSMENT_DETAILS_AND_INSTRUCTIONS}<Link target="_blank" href="https://amazon.awsapps.com/workdocs/index.html#/folder/bdc09c287f586bb8b001ed5fba508a4744d274f88521b4b4d4137e8c16ee9f1f
">{Constants.HERE}</Link>
            </p>
          </SpaceBetween>
          <Legend />
        </Grid>
      </Container>
      <Container className="horizontal-sroll">
        {state.dialogVisible ?
          <ColorAssessmentDialog
            dispatch={dispatch}
            state={state}
          />
          : <></>}
        {!state.assessmentResult && <Spinner />}
        {state.assessmentResult &&
          <table style={{ scrollMarginTop: 'auto' }}>
            <tbody>
              {getHeader(state.assessmentResult?.sectionCategoryMap)}
              {getSubHeader(state.assessmentResult?.sectionCategoryMap)}
              {getRows(state, dispatch)}
            </tbody>
          </table>
        }
      </Container>
    </SpaceBetween >
  );
}

export default ColorAssessment