import { Button, ExpandableSection, FormField, Modal, Select, SelectProps, SpaceBetween, Textarea } from "@amzn/awsui-components-react";
import { useState } from "react";
import Constants from "../../common/Constants";
import { getEnablerTitleAndQuestion } from "../Utils";
import { ViewState } from "./ColorAssessment";
import { Action, ActionName } from "./ColorAssesssmentActions";
import { getStatusSelectOption, StatusDetails, statusTags } from "./StatusTags";
import { updateQuestion } from "./UpdateQuestion";

export interface ColorAssessmentDialogProps {
    dispatch: React.Dispatch<Action>;
    state: ViewState;
}

/**
 * Dialog that pops up on clicking the table cell.
 * This has a list of status to select from and
 * text placeholder to add notes.
 * The update button will update the value in the backend.
 * 
 * @param props DialogProps containing the state and dispatch action 
 */
export const ColorAssessmentDialog = (props: ColorAssessmentDialogProps) => {
    const [statusChanged, setStatusChanged] = useState(false);
    const [notesChanged, setNotessChanged] = useState(false);
    const [updateInprogress, setUpdateInprogress] = useState(false);

    return (
        props.state.currentCell ?
            <Modal
                onDismiss={() => props.dispatch({
                    actionName: ActionName.CloseQuestion,
                })}
                visible={props.state.dialogVisible}
                closeAriaLabel="Close dialog"
                header={getEnablerTitleAndQuestion(props.state.currentCell)[0]}
            >
                <SpaceBetween size="m">
                    {getEnablerTitleAndQuestion(props.state.currentCell)[1]}

                    {props.state.currentCell.context?.text &&
                        <ExpandableSection header={Constants.CONTEXT}>{props.state.currentCell.context.text}</ExpandableSection>
                    }

                    <FormField errorText={statusChanged ? props.state.updateError : undefined}
                        label="Select Status"
                    >
                        <Select
                            selectedOption={props.state.selectedOption}
                            onChange={({ detail }) => {
                                setStatusChanged(true);
                                props.dispatch({
                                    actionName: ActionName.ChangeSelection,
                                    selectedOption: detail.selectedOption
                                });
                            }}
                            options={getStatusOptions()}
                            selectedAriaLabel="Selected"
                        />
                    </FormField>
                    <FormField errorText={notesChanged ? props.state.updateError : undefined} label="Add notes">
                        <Textarea
                            onChange={(ev) => {
                                setNotessChanged(true);
                                props.dispatch({
                                    actionName: ActionName.AddNotes,
                                    notes: ev.detail.value
                                })
                            }}
                            value={props.state.notes}
                            placeholder="Place your notes here"
                        />
                    </FormField>
                    <Button
                        formAction="none"
                        iconAlign="left"
                        loading={updateInprogress}
                        disabled={!(statusChanged || notesChanged)}
                        onClick={() => {
                            if (props.state.currentCell) {
                                setUpdateInprogress(true);
                                updateQuestion({
                                    state: props.state,
                                    dispatch: props.dispatch
                                }, setUpdateInprogress)
                            }
                        }}
                    >Update</Button>
                </SpaceBetween>
            </Modal>
            : <div />);
}

const getStatusOptions = (): SelectProps.Option[] => {
    const statusItems: SelectProps.Option[] = [];
    statusTags.forEach((value: StatusDetails, key: number) => {
        if (key !== 0) {

            const item: SelectProps.Option =
            {
                value: key.toString(),
                label: getStatusSelectOption(key)
            }
            statusItems.push(item);
        }
    });
    return statusItems;
}